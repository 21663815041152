import { createContext } from "react";

  const userdetail = {
       detail_info:'',
       setdetail_info :()=>{},
       token : '',
       settoken : ()=>{},
       emailname : '',
       setemailname : ()=>{},
  }

  


export const Logindetail = createContext(userdetail) 