import instance from "../Config/Config"


export const HOMEPAGE_BANNER = "banners"
export const PRODUCT_LIST = "product"
export const liveprice_url = "metal-prices"
export const Faq = "faq"
export const savingplan = "get_saving_plan"
export const similarproduct = "similar-products"
export const SaveShcema = "saving_plan_purchase"  
export const monthlyPay = "pay_now_saving_plan"  
export const getShcema = "get_purchase_transaction"  
export const getinvoice = "generate_invoice"  
export const savecontact = "contact_store"
export const Beneficiaries = "benificiar_save"
export const getBeneficiaries = "benificiar"
export const DeleteBeneficiaries = "benificiar_delete" 
export const bankdetail = "account_details"
export const pages = "pages"
export const saveplancancell = "saving_plan_cancel"
export const WHY_CHOOSE_US = "why_choose_us"
export const ENROLL_URL = "plan_banners"
export const INVESTING_URL = "get_investing"
export const DIGITALGOLD_URL = "get_digital_gold"
export const TRUSTED_URL = "get_trusted"
export const ABOUT_DETAIL_URL = "get_about"


 

export const homebannerapi = async()=>{
    const response =  await instance.get(`${HOMEPAGE_BANNER}`)
    return response?.data
}

export const PagesApi = async()=>{
    const response =  await instance.get(`${pages}?footer=1`)
    return response?.data
}

export const Singlepage = async(slug)=>{
    const response =  await instance.get(`${pages}?slug=${slug}`)
    return response?.data
}

export const Product_List_fun = async()=>{
    const response = await instance.get(`${PRODUCT_LIST}`)
    return response?.data
}

export const singleproduct =async(id)=>{
    const response = await instance.get(`${PRODUCT_LIST}/${id}`)
    return response?.data
}


export const similaprod_fun = async(id , category)=>{
    const response = await instance.get(`${similarproduct}/${id}/${category}`)
    return response?.data
}

export const getfaq_fun = async(value)=>{
    const response = await instance.get(`${Faq}?plan=${value}`)
    return response?.data
}

export const getlive_fun = async()=>{
    const response = await instance.get(`${liveprice_url}`)
    return response?.data
}

export const getSavingPlan_fun = async()=>{
    const response = await instance.get(`${savingplan}`)
    return response?.data
}

export const postSchemaSave = async (value)=>{
    const response = await instance.post(`${SaveShcema}`, value )
    return response.data
}

export const postMonthShec = async (value,)=>{
    const response = await instance.post(`${monthlyPay}`, value)
    return response.data
}

export const getShemesSchedule = async (slug , id)=>{
    const response = await instance.get(`${getShcema}?plan_status=${slug}${id ? `&purchase_id=${id}` : ''}`)
    return response.data
}

export const getInvoiceDown = async (id)=>{
    const response = await instance.get(`${getinvoice}?id=${id}`)
    return response.data
}

export const contactsave = async(value)=>{
    const response = await instance.post(`${savecontact}` , value)
    return response.data
}

export const saveBeneficiaries = async (value,id) => {
    const response = await instance.post(`${Beneficiaries}?id=${id}`, value)
    return response.data
}

export const RemoveBeneficiaries = async (id)=>{
    const response = await instance.delete(`${DeleteBeneficiaries}?id=${id}`)
    return response.data
}

export const fetchBeneficiaries = async ()=>{
    const response = await instance.get(`${getBeneficiaries}`)
    return response.data
}

// export const getBankDetail = async()=>{
//     const response = await instance.get(`${bankdetail}`)
//     return response.data
// }

export const SetPlanCancell = async(value)=>{
     const response = await instance.post(`${saveplancancell}?purchase_id=${value}`)
     return response.data
}
export const whychoose = async()=>{
    const response = await instance.get(`${WHY_CHOOSE_US}`)
    return response.data
}

export const Enroll_fun = async()=>{
    const response = await instance.get(`${ENROLL_URL}`)
    return response.data
}

export const Investing_fun = async()=>{
    const response = await instance.get(`${INVESTING_URL}`)
    return response?.data
}

export const Digitalgold_fun = async()=>{
    const response = await instance.get(`${DIGITALGOLD_URL}`)
    return response?.data
}

export const Gettrust_fun =async()=>{
     const response = await instance.get(`${TRUSTED_URL}`)
     return response?.data
}

export const Getabout_fun = async()=>{
    const response = await instance.get(`${ABOUT_DETAIL_URL}`)
    return response?.data
}
