import React, { useContext, useEffect } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Footer from "../footer/Footer.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import AgentDashboard from "./AgentDashboard.jsx";
import { useSelector } from "react-redux";
import ClientDashboard from "./ClientDashboard.jsx";
import { Logindetail } from "../context/Logindetail.js";
import { useNavigate } from "react-router-dom";

const MyDashboard = ({settings}) => {


    return (
        <>
            <HeadingBanner title={"Dashboard"} />
            <section>
                <div className="container">
                    <div className="mydashboard_layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-sm-12">
                                <Dashboard setting_data={settings} />
                            </div>

                            <div className="col-xl-9 col-lg-8 col-sm-12">
                                <ClientDashboard setting_data={settings} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MyDashboard