import React, { useCallback, useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useNavigate } from "react-router-dom";
import { Logindetail } from "../context/Logindetail.js";
import { Cancel_scheme, Getdigital_trans, GetPlanbenefit, PayDaily_Scheme, Post_PlanActive, Savedigitalplan } from "../api/RouteScheme2.js";
import moment from "moment";
import toast from "react-hot-toast";
import useRazorpay from "react-razorpay";
import { Shimmer } from "react-shimmer";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { SchemeDetails } from "../context/SchemeDetails.js";
import { ArrowLeft, BookmarkCheckFill, BookmarkStarFill, BookmarkXFill, PauseCircleFill, SlashCircleFill, X } from "react-bootstrap-icons";
const Transaction = ({ settings }) => {

    const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;


    const { detail_info } = useContext(Logindetail)
    const { schemelist } = useContext(SchemeDetails)
    const [ALlData_list, setALlData_list] = useState([])
    const [sort, setsort] = useState(5)
    const [PlanActive, setPlanActive] = useState()
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemaData, setshemaData] = useState()
    const [price, setprice] = useState()
    const [isloading, setisloading] = useState(true)
    // const [Entername, setEntername] = useState()
    const [spinner, setspinner] = useState(false)
    const [Transaction, setTranscation] = useState([])
    const [Transname, setTransname] = useState()
    const [spinid, setspinid] = useState()
    const [planbenifit, setplanbenifit] = useState()
    const [BenifitAmt, setBenifitAmt] = useState()
    const [checked, setchecked] = useState()
    const [checked2, setchecked2] = useState()
    const [spinner1, setspinner1] = useState()
    const [activebank, setactivebank] = useState()
    const [cancel_slug, setcancel_slug] = useState()
    const [finalamount, setfinalamount] = useState()
    const [actid, setactid] = useState()
    const [plandetails, setpladetails] = useState()
    const [statuslist, setstatuslist] = useState({
        'active': '',
        'hold': '',
        'complete': '',
        'suspend': '',
        'cancel': ''
    })
    const [Kycactive, setKycactive] = useState()
    const [Transactionid, setTranscationid] = useState()
    const [transfile, settransfile] = useState()
    const [file1, setfile1] = useState()
    const [process_type, setprocess_type] = useState()
    const [clientamount, setclientamount] = useState()
    const [monthlydata, setmonthlydata] = useState([])

    const router = useNavigate()




    const getTransaction = async (slug) => {
        setisloading(true)
        Getdigital_trans(slug)
            .then((res) => {
                setALlData_list(res?.data)
                setactivebank(res?.bank_details)
                setcancel_slug(res?.cancel_slug)
                setpladetails(res?.plan)
                setKycactive(res?.kyc_details)
                setisloading(false)
                setstatuslist(previousState => {
                    return { ...previousState, active: res?.active_count, hold: res?.hold_count, complete: res?.complete_count, suspend: res?.suspended_count, cancel: res?.cancel_count }
                })
                setfinalamount(res?.final_amount)
                if (Math.floor(parseInt(res?.data[0]?.total_gram)) >= 1 && res?.data[0]?.plan_active === 0 && res?.data[0]?.cancel_status === 0) {
                    getactive(res?.data[0]?.id)
                }
            })
            .catch((err) => {
                console.log(err)
                setisloading(false)
            })

    }

    const getactive = async (id) => {
        document.getElementById('activepopmsg').click()
        setPlanActive(id)
    }


    const handleactive = () => {
        Post_PlanActive(PlanActive)
            .then((res) => {
                getTransaction(1)
                toast.success(res?.data)
                setPlanActive()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handlePayment = useCallback(async () => {
        setspinner(true)
        const options = {
            key: RAZAR_KEY,
            amount: (clientamount) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {


                if (ALlData_list.length >= 1) {

                    const body = {
                        "amount": clientamount,
                        "purchase_id": shemaData?.id,
                        "transaction_id": res?.razorpay_payment_id,
                        "payment_method": 'razor_pay',
                    }

                    PayDaily_Scheme(body).then(res => {
                        toast.success("Payment success!")
                        setShowRazorpayModal(false);
                        setspinner(false)
                        getTransaction(1)

                    }).catch(err => {
                        console.log('err', err.message)
                        setspinner(false)
                    })
                }

                else {

                    const body = {
                        "digital_plan_id": shemaData?.id,
                        "amount": clientamount,
                        "transaction_id": res?.razorpay_payment_id,
                    }
                    Savedigitalplan(body).then(res => {
                        if (res?.error) {
                            return toast.error(res?.error ?? 'Somthing Went Wrong!')
                        }
                        toast.success(`Payment successful from ${plandetails?.name}`)
                        getTransaction(1)

                        setShowRazorpayModal(false);
                    }).catch(err => {
                        console.log('err', err.message)
                    })
                }


            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemaData]);


    const PostCancel_id = (id) => {

        if (!checked) {
            toast.error('Please Agree Terms & Condition')
        }
        else {

            document.getElementById('cancelpopmsg').click()
            Cancel_scheme(id)
                .then((res) => {
                    toast.success(res?.data)
                    getTransaction(1)
                    setactid(1)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    const handlespinner = (data) => {
        setspinid(data)
        setspinner(true)
    }

    const handledownload = (value) => {
        const baseurl = process.env.REACT_APP_BASE_URL
        let fileName = `${moment(value?.created_at).format('DD-MM-YYYY')}-invoice.pdf`;
        const url = `${baseurl}digital_invoice?id=${value?.id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setspinner(false)
                setspinid()
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
                setspinner(false)
                setspinid()
            });
    }

    const schemebeniftapi = () => {
        setspinner1(true)
        GetPlanbenefit()
            .then((res) => {
                console.log(res)
                setplanbenifit(res?.months)
                setspinner1(false)
            })
            .catch((err) => {
                console.log(err)
                setspinner(false)
            })
    }


    const paginationModel = { page: 0, pageSize: 5 };

    useEffect(() => {
        getTransaction(1)
        setactid(1)
    }, [])

    const paymentfun = (e) => {
        e.preventDefault()

        if (process_type == '1') {

            setShowRazorpayModal(true);
            handlePayment()
            document.getElementById('payment-pup').click()
            document.getElementById('Rupees').value = null
            console.log('shmedata', shemaData)
        }
        else if (process_type == '2') {
            document.getElementById('payment-pup').click()
            document.getElementById('offlinepop-pup').click()
            document.getElementById('transaction-id').value = null
            setfile1()
            settransfile()
            setTranscationid()
        }
        else if (process_type == '3') {
            document.getElementById('payment-pup').click()
            document.getElementById('bankpop-pup').click()
            document.getElementById('transaction-id2').value = null
            setTranscationid()
            setfile1()
            settransfile()
        }
        else {
            toast.error('please select payment method')
        }

    }

    const upimethod = (e) => {
        e.preventDefault()

        if (Transactionid || transfile) {
            if (ALlData_list.length >= 1) {

                setspinner(true)
                const formdata = new FormData()
                formdata.append("amount", clientamount)
                formdata.append("purchase_id", shemaData?.id)
                formdata.append("transaction_id", Transactionid ? Transactionid : '123')
                formdata.append("payment_method", 'upi')
                formdata.append('uploads', transfile)


                PayDaily_Scheme(formdata).then(res => {
                    toast.success("Payment success!")
                    setShowRazorpayModal(false);
                    document.getElementById('offlinepop-pup').click()
                    setspinner(false)
                    getTransaction(1)

                }).catch(err => {
                    console.log('err', err.message)
                    setspinner(false)
                })
            }

            else {
                setspinner(true)
                const formdata = new FormData()
                formdata.append("digital_plan_id", shemaData?.id)
                formdata.append("amount", clientamount)
                formdata.append("transaction_id", Transactionid ? Transactionid : '123')
                formdata.append("payment_method", 'upi')
                formdata.append('uploads', transfile)

                Savedigitalplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${plandetails?.name}`)
                    getTransaction(1)
                    document.getElementById('offlinepop-pup').click()
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })
            }
        }
        else {
            toast.error('please Upload the transaction slip or enter the transaction ID')
        }

    }

    const Bankmethod = (e) => {
        e.preventDefault()

        if (Transactionid || transfile) {
            if (ALlData_list.length >= 1) {
                setspinner(true)
                const formdata = new FormData()
                formdata.append("amount", clientamount)
                formdata.append("purchase_id", shemaData?.id)
                formdata.append("transaction_id", Transactionid ? Transactionid : '123')
                formdata.append("payment_method", 'bank')
                formdata.append('uploads', transfile)


                PayDaily_Scheme(formdata).then(res => {
                    toast.success("Payment success!")
                    setShowRazorpayModal(false);
                    setspinner(false)
                    document.getElementById('bankpop-pup').click()
                    getTransaction(1)
                    setspinner(false)

                }).catch(err => {
                    console.log('err', err.message)
                    setspinner(false)
                })
            }

            else {
                setspinner(true)
                const formdata = new FormData()
                formdata.append("digital_plan_id", shemaData?.id)
                formdata.append("amount", clientamount)
                formdata.append("transaction_id", Transactionid ? Transactionid : '123')
                formdata.append("payment_method", 'bank')
                formdata.append('uploads', transfile)

                Savedigitalplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${plandetails?.name}`)
                    getTransaction(1)
                    document.getElementById('bankpop-pup').click()
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })
            }
        }
        else {
            toast.error('please Upload the transaction slip or enter the transaction ID')
        }

    }

    const screenshot = (e) => {
        setfile1(e.target.files[0]?.name)
        settransfile(e.target.files[0])
    }


    const columns = [

        { field: 's_no', headerName: 'S.NO', width: 100 },

        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 140,
            valueGetter: (value, row) => `${row?.total_gram ? row?.total_gram : '0'}(gm)`
            // valueGetter: (value, row) => `${row.digital_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'amount',
            headerName: 'AMOUNT',
            width: 140,
            valueGetter: (value, row) => `${row?.total_amount ? row?.total_amount : '-'}`,
            // valueGetter: (value, row) => `₹ ${row.digital_transaction?.reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'created_at',
            headerName: 'PURCHASE DATE',
            width: 200,
            valueGetter: (value, row) => `${row?.created_at ? moment(row?.created_at).format('DD-MM-YYYY') : '-'}`,
        },

        {
            field: 'Activated_Date',
            headerName: 'ACTIVATED DATE',
            // valueGetter: (value, row) => `${row?.active_date ? moment(row?.digital_transaction[0]?.active_date).format('DD-MM-YYYY') : '-'}`,
            valueGetter: (value, row) => `${row?.digital_transaction[0]?.active_date === null ? '-' : moment(row?.digital_transaction[0]?.active_date).format('DD-MM-YYYY')}`,
            width: 200,
        },
        // {
        //     field: 'withdraw_date',
        //     headerName: 'Withdraw Date',
        //     width: 100,
        //     valueGetter: (value, row) => `${row?.withdraw_date ? row?.withdraw_date : '-'}`,
        //     sortable: false,
        // },
        {
            field: 'view',
            headerName: 'VIEW',
            width: 85,
            sortable: false,
            renderCell: (params) => (
                <>
                    {/* <img
                        src="\assets\iocns\eye.png"
                        // onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row?.purchase_transaction)}
                        alt=""
                        width={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => params.row?.cancel_status == 1 ? {} : handleTransaction(params?.row) }
                    /> */}

                    {
                        spinner1 && spinid == params?.row?.id ? <span className="spinner-border spinner-border-sm mx-2 " role="status"></span> :
                            <img
                                src="\assets\iocns\eye.png"
                                onClick={() => { schemebeniftapi(); setBenifitAmt(1000); setspinid(params?.row?.id) }}
                                alt=""
                                width={20}
                                style={{ cursor: 'pointer' }}
                            />
                    }

                </>
            )
        },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 100,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img alt='' src="\assets\iocns\invoice.png" onClick={() => { handledownload(params); handlespinner(params?.row?.id) }} className="ytjsfvdjd" />)

                    }
                </>
            ),
            sortable: false,
        }
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 120,
        //     renderCell: (params) => (
        //         <>
        //             {
        //                 params?.row?.status === 1 ? <button className="btn bg-success  gold-activebtn text-white  border-0" disabled>Completed</button> : params?.row?.cancel_status === 1 ? <button className="btn bg-secondary gold-activebtn text-white  border-0" disabled>InActive</button> : params?.row?.plan_active === 1 ? <button className="btn border-0 bg-primary text-white gold-activebtn" disabled={true}>Actived</button> : Math.floor(parseInt(params?.row?.total_gram)) >= 1 ? <button className="btn bg-warning gold-activebtn border-0  text-white" onClick={() => { getactive(params?.row?.id) }}>Active</button> : <button className="btn bg-secondary  gold-activebtn text-white  border-0" disabled>InActive</button>

        //             }

        //         </>
        //     ),
        //     sortable: false,
        // },
        // {
        //     field: 'payment',
        //     headerName: 'Payment',
        //     width: 120,
        //     renderCell: (params) => (
        //         <>
        //             {params?.row?.cancel_status === 1 || params?.row?.status === 1 ? <button className="btn  paynsd2  border-0 text-white" >PAY NOW</button> : <button className="btn paynsd text-white" onClick={() => { setshemaData(params?.row); document.getElementById('openPriceplanpopu').click() }}>PAY NOW</button>}
        //         </>
        //     ),
        //     sortable: false,
        // },
        // {
        //     field: 'cancel',
        //     headerName: 'Cancel',
        //     width: 120,
        //     renderCell: (params) => (
        //         <>

        //             {
        //                 params?.row?.status === 1 ? <button className="btn bg-secondary  gold-cancellbtn border-0 text-white" disabled={true} >Cancel</button> : params?.row?.cancel_status === 1 ? <button className="btn bg-danger border-0 text-white gold-cancellbtn" disabled={true}>Cancelled </button> : moment(params?.row?.created_at, 'YYYY-MM-DD').diff(new Date(), 'days').toString().split('-')[1] >= 90 ? <button className="btn bg-danger border-0 gold-cancellbtn text-white" onClick={() => { PostCancel_id(params?.row?.id) }} >Cancel</button> :
        //                     <button className="btn bg-secondary gold-cancellbtn border-0 text-white" disabled={true}  >Cancel</button>

        //             }
        //         </>
        //     ),
        //     sortable: false,
        // }
    ];

    const columns2 = [

        { field: 's_no', headerName: 'S.NO', width: 140 },

        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 172,
            valueGetter: (value, row) => `${row?.total_gram ? row?.total_gram : '0'}(gm)`
            // valueGetter: (value, row) => `${row.digital_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'amount',
            headerName: 'AMOUNT',
            width: 172,
            valueGetter: (value, row) => `${row?.total_amount ? row?.total_amount : '-'}`,
            // valueGetter: (value, row) => `₹ ${row.digital_transaction?.reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'cancell_date',
            headerName: 'CANCELLED DATE',
            width: 200,
            valueGetter: (value, row) => `${row?.updated_at ? moment(row?.updated_at).format('DD-MM-YYYY') : '-'}`,
        },


        {
            field: 'view',
            headerName: 'VIEW',
            width: 140,
            sortable: false,
            renderCell: (params) => (
                <>
                    {
                        spinner1 && spinid == params?.row?.id ? <span className="spinner-border spinner-border-sm mx-2 " role="status"></span> :
                            <img
                                src="\assets\iocns\eye.png"
                                onClick={() => { schemebeniftapi(); setBenifitAmt(1000); setspinid(params?.row?.id) }}
                                alt=""
                                width={20}
                                style={{ cursor: 'pointer' }}
                            />
                    }

                </>
            )
        },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 140,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img alt='' src="\assets\iocns\invoice.png" onClick={() => { handledownload(params); handlespinner(params?.row) }} className="ytjsfvdjd" />)

                    }
                </>
            ),
            sortable: false,
        }
    ]
    const columns3 = [

        { field: 's_no', headerName: 'S.NO', width: 90 },

        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 130,
            valueGetter: (value, row) => `${row?.total_gram ? row?.total_gram : '0'}(gm)`
            // valueGetter: (value, row) => `${row.digital_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'amount',
            headerName: 'AMOUNT',
            width: 130,
            valueGetter: (value, row) => `${row?.total_amount ? row?.total_amount : '-'}`,
            // valueGetter: (value, row) => `₹ ${row.digital_transaction?.reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'completed_date',
            headerName: 'COMPLETED DATE',
            width: 200,
            valueGetter: (value, row) => `${row?.updated_at ? moment(row?.updated_at).format('DD-MM-YYYY') : '-'}`,
        },
        {
            field: 'withdraw_type',
            headerName: 'WITHDRAW TYPE',
            width: 160,
            valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
            sortable: false,
        },
        {
            field: 'withdraw_date',
            headerName: 'WITHDRAW DATE',
            width: 160,
            valueGetter: (value, row) => `${moment(row?.withdraw_date).format('DD-MM-YYYY') ?? '-'}`,
            sortable: false,
        },

        // {
        //     field: 'view',
        //     headerName: 'VIEW',
        //     width: 80,
        //     sortable: false,
        //     renderCell: (params) => (
        //         <>
        //             {
        //                 spinner1 && spinid == params?.row?.id ? <span className="spinner-border spinner-border-sm mx-2 " role="status"></span> :
        //                     <img
        //                         src="\assets\iocns\eye.png"
        //                         onClick={() => { schemebeniftapi(); setBenifitAmt(1000); setspinid(params?.row?.id) }}
        //                         alt=""
        //                         width={20}
        //                         style={{ cursor: 'pointer' }}
        //                     />
        //             }

        //         </>
        //     )
        // },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 95,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img alt='' src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)

                    }
                </>
            ),
            sortable: false,
        }
    ]
    const columns4 = [

        { field: 's_no', headerName: 'S.NO', width: 140 },

        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 172,
            valueGetter: (value, row) => `${row?.total_gram ? row?.total_gram : '0'}(gm)`
            // valueGetter: (value, row) => `${row.digital_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'amount',
            headerName: 'AMOUNT',
            width: 172,
            valueGetter: (value, row) => `${row?.total_amount ? row?.total_amount : '-'}`,
            // valueGetter: (value, row) => `₹ ${row.digital_transaction?.reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },


        {
            field: 'hold_Date',
            headerName: 'HOLD DATE',
            // valueGetter: (value, row) => `${row?.active_date ? moment(row?.digital_transaction[0]?.active_date).format('DD-MM-YYYY') : '-'}`,
            valueGetter: (value, row) => `${row?.updated_at ? moment(row?.updated_at).format('DD-MM-YYYY') : '-'}`,
            width: 200,
        },

        {
            field: 'view',
            headerName: 'VIEW',
            width: 140,
            sortable: false,
            renderCell: (params) => (
                <>

                    {
                        spinner1 && spinid == params?.row?.id ? <span className="spinner-border spinner-border-sm mx-2 " role="status"></span> :
                            <img
                                src="\assets\iocns\eye.png"
                                onClick={() => { schemebeniftapi(); setBenifitAmt(1000); setspinid(params?.row?.id) }}
                                alt=""
                                width={20}
                                style={{ cursor: 'pointer' }}
                            />
                    }

                </>
            )
        },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 140,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img alt='' src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)

                    }
                </>
            ),
            sortable: false,
        }

    ]
    const columns5 = [

        { field: 's_no', headerName: 'S.NO', width: 140 },

        {
            field: 'weight',
            headerName: 'WEIGHT',
            width: 172,
            valueGetter: (value, row) => `${row?.total_gram ? row?.total_gram : '0'}(gm)`
            // valueGetter: (value, row) => `${row.digital_transaction?.reduce((accumulator, current) => accumulator + (current?.gold_gram ?? ''), 0)} (gm)` ?? '0',
        },
        {
            field: 'amount',
            headerName: 'AMOUNT',
            width: 172,
            valueGetter: (value, row) => `${row?.total_amount ? row?.total_amount : '-'}`,
            // valueGetter: (value, row) => `₹ ${row.digital_transaction?.reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },


        {
            field: 'suspended_date',
            headerName: 'SUSPENDED DATE',
            // valueGetter: (value, row) => `${row?.active_date ? moment(row?.digital_transaction[0]?.active_date).format('DD-MM-YYYY') : '-'}`,
            valueGetter: (value, row) => `${row?.updated_at ? moment(row?.updated_at).format('DD-MM-YYYY') : '-'}`,
            width: 200,
        },

        {
            field: 'view',
            headerName: 'VIEW',
            width: 140,
            sortable: false,
            renderCell: (params) => (
                <>

                    {
                        spinner1 && spinid == params?.row?.id ? <span className="spinner-border spinner-border-sm mx-2 " role="status"></span> :
                            <img
                                src="\assets\iocns\eye.png"
                                onClick={() => { schemebeniftapi(); setBenifitAmt(1000); setspinid(params?.row?.id) }}
                                alt=""
                                width={20}
                                style={{ cursor: 'pointer' }}
                            />
                    }

                </>
            )
        },

        {
            field: 'invoice',
            headerName: 'INVOICE',
            width: 140,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img alt='' src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)

                    }
                </>
            ),
            sortable: false,
        }

    ]

    return (
        <>
            <HeadingBanner title={settings[0]?.digital_plan ?? 'Loading...'} />
            <section>

                <div className="container">
                    <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"> sssdsdsd</button>

                    <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content schemecard">
                                <div className="modal-header">
                                    <h1 className="modal-title schemename" id="razarpaypopupLabel">{`${shemaData?.digital_plan?.name ?? shemaData?.name}(Cash Back)`}</h1>

                                    <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('Rupees').value = null; setprice(null) }}></button>
                                </div>

                                <div className="modal-body">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!price) {
                                            toast.error("Please Enter Price!")
                                        } else
                                            if (parseInt(price) < parseInt(shemaData?.digital_plan?.min_price ?? shemaData?.min_price)) {
                                                toast.error(`Minimum Amount is ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price}!`)
                                            } else if (parseInt(price) > shemaData?.digital_plan?.max_price ?? shemaData?.max_price) {
                                                toast.error(`Maximum Amount is ${shemaData?.digital_plan?.max_price ?? shemaData?.max_price}!`)
                                            }
                                            else if (checked2 !== true) {
                                                toast.error('please agree Terms and Conditions')
                                            }
                                            else {
                                                // openrazar_function()

                                                document.getElementById('closepriceModal')?.click();
                                                document.getElementById('payment-pup').click()
                                                document.getElementById('inlineRadio1').checked = false;
                                                document.getElementById('inlineRadio2').checked = false;
                                                document.getElementById('inlineRadio3').checked = false;
                                                setprocess_type();
                                                setclientamount(price)
                                            }
                                    }}>
                                        <div className="mb-3">
                                            <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price} to Maximum Amount ${shemaData?.digital_plan?.max_price ?? shemaData?.max_price})`}</small></label>
                                            <input type="text" maxLength={10} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price}`} />
                                        </div>
                                        <div className="mb-3">
                                            <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked2(e.target.checked) }} />

                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                                {/* I  agree {
                                            footerpage ? (<a href={`pages/${footerpage[0]?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>) : (<span className='termsborder'>Terms and Condition</span>)
                                        } */}
                                                I agree <a href={`pages/${shemaData?.page?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                            </label>
                                        </div>

                                        <div className="mb-3">
                                            <div className="update-profile  mx-2">
                                                <button className="text-white" type="submit">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="siptrans-content position-relative"  >
                                    <div className="siptrans-head pb-3">
                                        {
                                            // isloading ? <h4><Shimmer className="rounded " width={180} height={30} /></h4> :
                                            <div className="d-flex align-items-center gap-2">
                                                <h4> {planbenifit ? <img src="/assets/iocns/Back.png" className="cursor_pointer" alt="" onClick={() => setplanbenifit(null)} style={{ width: '24px' }} /> : ''} {settings ? settings[0]?.digital_plan : 'Loading...'}</h4>
                                                {
                                                    ALlData_list[0]?.plan_active === 1 && actid === 1 ? <h6 className="active-status">Activated</h6> : ''
                                                }
                                            </div>
                                        }
                                    </div>

                                    {
                                        !planbenifit ?
                                            <div className="row mb-3 mb-md-5">
                                                <div className="cox-xl-33">
                                                    <div className={actid === 1 ? "statustact my-2" : ' my-2'}>
                                                        <div className='plan-viewcard comp status2' onClick={() => { getTransaction(1); setactid(1) }}>
                                                            <div className="plan-count">
                                                                <h4>Active</h4>
                                                                <h2>{statuslist?.active ? statuslist?.active : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkCheckFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="cox-xl-33">
                                                    <div className={actid === 3 ? "statustact my-2" : ' my-2'}>
                                                        <div className='plan-viewcard comp status1' onClick={() => { getTransaction(3); setactid(3) }}>

                                                            <div className="plan-count ">
                                                                <h4>Completed</h4>
                                                                <h2>{statuslist?.complete ? statuslist?.complete : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkStarFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="cox-xl-33">
                                                    <div className={actid === 4 ? "statustact my-2" : ' my-2'}>

                                                        <div className='plan-viewcard comp status3' onClick={() => { getTransaction(4); setactid(4) }}>
                                                            <div className="plan-count">
                                                                <h4>Hold</h4>
                                                                <h2>{statuslist?.hold ? statuslist?.hold : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <PauseCircleFill />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="cox-xl-33">
                                                    <div className={actid === 2 ? "statustact my-2" : ' my-2'}>
                                                        <div className='plan-viewcard comp status4' onClick={() => { getTransaction(2); setactid(2) }}>
                                                            <div className="plan-count">
                                                                <h4>Cancelled</h4>
                                                                <h2>{statuslist?.cancel ? statuslist?.cancel : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkXFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cox-xl-33">
                                                    <div className={actid === 5 ? "statustact my-2" : ' my-2'}>
                                                        <div className='plan-viewcard comp status5' onClick={() => { getTransaction(5); setactid(5) }}>
                                                            <div className="plan-count">
                                                                <h4>Suspended</h4>
                                                                <h2>{statuslist?.suspend ? statuslist?.suspend : 0}</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <SlashCircleFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''
                                    }


                                    {
                                        isloading ? (

                                            <div className="tableload  mb-tableload text-center position-absolute">
                                                <div class="spinner-border text-primary text-center" role="status"></div>
                                            </div>

                                        )

                                            : planbenifit ? (
                                                <div className="schematable dg-scheme">
                                                    <div className='d-flex gap-3'>
                                                        <div>
                                                            <div className='mb-2 leaft2 text-center G1'>
                                                                <h5 className='text-light'>Gold Investment Leaf Scheme</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center  G2'>
                                                                <h5>3 months (0-90 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G3'>
                                                                <h5>6 months (90-180 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G4'>
                                                                <h5>9 months (180 - 270 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G5'>
                                                                <h5>12 months (270 - 360 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G6'>
                                                                <h5>2nd Year</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G7'>
                                                                <h5>3rd Year</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center C1'>
                                                                <h5>GreenHeap Gold approx. ( {`Rs. ${BenifitAmt} `} )</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2 '>
                                                                <h5> {`(${parseFloat(planbenifit?.three_months.gold) + '%' + 'X' + planbenifit?.three_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.six_months.gold) + '%' + 'X' + planbenifit?.six_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.nine_months.gold) + '%' + 'X' + planbenifit?.nine_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.one_year.gold) + '%' + 'X' + planbenifit?.one_year?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.two_years.gold) + '%' + 'X' + planbenifit?.two_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.three_years.gold) + '%' + 'X' + planbenifit?.three_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center C1'>
                                                                <h5>GreenHeap Silver Bonus</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2 '>
                                                                <h5> {`(${parseFloat(planbenifit?.three_months.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.three_months?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.six_months.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.six_months?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.nine_months.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.nine_months?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.one_year.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.one_year?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.two_years.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.two_years?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.three_years.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.three_years?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center C1'>
                                                                <h5>GreenHeap Gold + Silver Bonus</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5> {`(${Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2))}  </h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center d-block F1'>
                                                                <h5 className="text-white">Sip Total Returns</h5>
                                                                <h5 className="text-white">(Cash Back)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F2'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F3'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F4'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F5'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F6'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F7'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )

                                                : actid === 1 && ALlData_list?.length === 0 ? (

                                                    <div className="addbank-ac text-center border py-3">
                                                        <div className="bankimage my-3  ">
                                                            <img src="\assets\iocns\pure gold.png" className="img-fluid rounded p-1 " style={{ border: "1px solid #991313" }} />
                                                        </div>
                                                        <div className="addbank-info ">
                                                            <h5>{settings ? settings[0]?.digital_plan : ''}</h5>
                                                        </div>
                                                        <div className="addbank-btn text-white my-3">
                                                            <button className="text-white" onClick={() => {
                                                                setshemaData(plandetails); document.getElementById('openPriceplanpopu').click(); document.getElementById('agreecheck').checked = false;
                                                                setchecked2(false)
                                                            }} data-bs-whatever="@getbootstrap" >Buy Scheme</button>
                                                        </div>
                                                    </div>

                                                )

                                                    : (
                                                        <>

                                                            {
                                                                actid === 1 && ALlData_list.length >= 1 ? (
                                                                    <div className="d-sm-flex align-items-center d-block  schowner gap-3 mt-xl-4 mt-lg-0">
                                                                        <h5 className="m-0 mb-2 mb-sm-0">Welcome <span className="loginname">{detail_info?.name}</span> !</h5>

                                                                        {
                                                                            ALlData_list[0]?.cancel_status == '1' ? <button className="btn paynsd2 secondplan-btn  border-0 text-white" >PAY NOW</button> :
                                                                                <button className="btn paynsd-new secondplan-btn mx-3 mx-sm-0" type="button" onClick={() => {
                                                                                    setshemaData(ALlData_list[0]); document.getElementById('openPriceplanpopu').click(); document.getElementById('agreecheck').checked = false;
                                                                                    setchecked2(false)
                                                                                }}>PAY NOW</button>
                                                                        }

                                                                        {
                                                                            ALlData_list[0]?.cancel_status == '1' ? <button className="btn btn-danger secondplan-btn" disabled={true}>CANCELLED</button> : <button className="btn cancel-btns secondplan-btn" onClick={() => { document.getElementById('cancelpopmsg').click(); document.getElementById('dgplan-check').checked = false; setchecked(false) }}>CANCEL</button>
                                                                        }

                                                                    </div>
                                                                ) : ''
                                                            }

                                                            {
                                                                ALlData_list?.length > 0 ? (

                                                                    <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                                                        <DataGrid
                                                                            // rows={ALlData_list[0]?.digital_transaction.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                                            rows={ALlData_list.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                                            columns={actid == '1' ? columns : actid == '2' ? columns2 : actid == '3' ? columns3 : actid == '4' ? columns4 : columns5}
                                                                            initialState={{ pagination: { paginationModel } }}
                                                                            pageSizeOptions={[5, 10]}
                                                                            sx={{
                                                                                border: 0, marginTop: 3,
                                                                                '.MuiDataGrid-iconButtonContainer': {
                                                                                    visibility: 'visible',
                                                                                },
                                                                                '.MuiDataGrid-sortIcon': {
                                                                                    opacity: 'inherit !important',
                                                                                },

                                                                            }}
                                                                            disableColumnFilter
                                                                            disableColumnMenu
                                                                            disableColumnSelector
                                                                            disableDensitySelector
                                                                            className="schemedata-table"
                                                                            slots={{ toolbar: GridToolbar }}
                                                                            slotProps={{
                                                                                toolbar: {
                                                                                    showQuickFilter: true,
                                                                                    printOptions: { disableToolbarButton: true },
                                                                                    csvOptions: { disableToolbarButton: true },

                                                                                },
                                                                            }}

                                                                        />
                                                                    </Paper>
                                                                ) : <div className="text-center no-commision">
                                                                    <img src="\assets\noData.png" className="img-fluid" alt="" />
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="activeboxmsg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#activeboxmsg" id="activepopmsg"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('activeboxmsg').click() }} ></button>
                            {/* <h5 className="modal-title" id="exampleModalLongTitle">{settings ? `${settings[0]?.digital_plan}(cashback)` : ''}</h5> */}
                        </div>
                        <div className="modal-body  text-center">
                            <div className="popactive-img">
                                <img src="\assets\iocns\physical gold.png" />
                            </div>
                            <div className="pop-submsg pt-2 d-flex justify-content-center text-center">
                                <div className="plan-name-sub mt-2">
                                    <h3 className="">{settings ? settings[0]?.digital_plan : ''}</h3>
                                    <p className="mb-0">The total gold is expected to be around 1 gram Would you like to activate the plan</p>
                                </div>
                            </div>

                            <div className="modal-footer border-0 justify-content-center ">
                                <button type="button " className="btn bg-success px-4 text-white" data-dismiss="modal" onClick={handleactive}>Activate</button>
                                {/* <button type="button " className="btn bg-danger text-white" data-dismiss="modal">Close</button> */}
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <div className="modal fade" id="cancelboxmsg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#cancelboxmsg" id="cancelpopmsg"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('cancelboxmsg').click() }} ></button>
                            {/* <h5 className="modal-title" id="exampleModalLongTitle">{settings ? settings[0]?.digital_plan : ''}</h5> */}
                        </div>
                        <div className="modal-body pt-0  text-center">
                            <div className="pop-cancel-icon">
                                <img src="\assets\iocns\warning.png" className="img-fluid" alt="" />
                            </div>
                            <div className="plan-name-sub mt-2">
                                <h3 className="">{settings ? settings[0]?.digital_plan : ''}</h3>
                            </div>
                            <div className="pop-submsg text-center ">
                                {
                                    activebank === 0 ? <h4>
                                        To complete the cancellation process, kindly submit your bank details.
                                    </h4> :
                                        Kycactive === 0 && finalamount >= 200000 ? <h4>
                                            Your account balance has exceeded ₹2,00,000. If you wish to cancel your plan,  kindly submit  KYC details.
                                        </h4>
                                            : <h4 className="">If you cancel, the final amount will be {finalamount ? `₹${finalamount}` : ''} excluding  service charges.</h4>
                                }

                            </div>
                            <div className="mt-3">

                                {
                                    activebank ? activebank === 1 ? (
                                        <>
                                            <input className="form-check-input" type="checkbox" value="" id="dgplan-check" onChange={(e) => { setchecked(e.target.checked) }} />

                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                                I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                            </label>
                                        </>
                                    ) : '' : ''
                                }


                                {/* {
                                    activebank === 1 ? (<>
                                        <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                        <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                            I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                        </label>
                                    </>)
                                        :  ''
                                } */}
                            </div>
                            <div className="modal-footer border-0 justify-content-center pop-can-btn">
                                {
                                    activebank === 0 ? <button type="button " className="btn bg-danger px-4 text-white" onClick={() => { document.getElementById('cancelpopmsg').click(); router('/user/mybank-account') }} >Submit</button> :
                                        Kycactive === 0 && finalamount >= 200000 ? <button type="button " className="btn bg-danger text-white  px-4" onClick={() => { document.getElementById('cancelpopmsg').click(); router('/user/kyc') }} >Submit</button> :
                                            <button type="button " className="btn bg-danger text-white  px-4" onClick={() => { PostCancel_id(ALlData_list[0]?.id) }} >Cancel</button>
                                }
                                {/* <button type="button " className="btn bg-secondary text-white" data-dismiss="modal">Close</button> */}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* qr-code-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#offlinebox" id='offlinepop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="offlinebox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-2' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('offlinepop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{settings[0]?.saving_plan}(Cash Back)</h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('offlinepop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="qr-detail text-center">
                                    <h6>{settings[0]?.account_holder}</h6>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className="qr-upiid text-center">
                                        <h6 className='mb-0'>Vyapar.171813426519@hdfcbank</h6>
                                    </div>
                                </div>
                                <div className="qq-sacn-img p-2">
                                    <img src={settings[0]?.qr_code} alt='' className='img-fluid' />
                                </div>
                                <form onSubmit={upimethod}>
                                    <div className="uplo-img ">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>

                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }

                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" aria-label="transaction-id" id='transaction-id' onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>
                                    <div className="update-profile">
                                        <button className="text-white" type="submit">{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* payment-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#paymentbox" id='payment-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="paymentbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{settings[0]?.saving_plan}(Cash Back)</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('payment-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="pm-ti">
                                    <h5> Choose Payment Method</h5>
                                </div>
                                <div className="pm-choice ">
                                    <form onSubmit={paymentfun}>
                                        <div className={process_type == '1' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes p-2 d-flex align-items-center gap-2">
                                                <input class="form-check-input mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                <label class="form-check-label" for="inlineRadio1">Online Razarpay</label>
                                                <div className='razoricon'><img src='\assets\images\razar_icon.png' className='img-fluid' alt='' /></div>
                                            </div>
                                        </div>
                                        <div className={process_type == '2' ? 'form-check act' : 'form-check'} >
                                            <div className="selecttypes p-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                <label class="form-check-label" for="inlineRadio2">QR code / UPI</label>
                                                <div className='razoricon'><img src='\assets\images\upi.png' className='img-fluid' alt='' /></div>
                                            </div>
                                        </div>
                                        <div className={process_type == '3' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes py-3 px-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="3" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                <label class="form-check-label" for="inlineRadio3">Bank Details</label>
                                                <div className='banic'><img src='\assets\iocns\bank.png' className='img-fluid' alt='' /></div>
                                            </div>
                                        </div>
                                        <div className="update-profile ">
                                            <button className="text-white" type="submit">Continue</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* bandetail-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#bankbox" id='bankpop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="bankbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-1' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('bankpop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{settings[0]?.saving_plan}(Cash Back) </h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('bankpop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <h5 className='mb-4'>Bank Details</h5>

                                <div className="acc-holder">
                                    <img src='\assets\iocns\Profile.png' className='img-fluid' alt="" /><span>{settings[0]?.account_holder}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\accountnumber.png' className='img-fluid' alt="" /><span>{settings[0]?.account_number}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\bank.png' className='img-fluid' alt="" /><span>{settings[0]?.bank_name}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\branchlocatin.png' className='img-fluid' alt="" /><span>{settings[0]?.branch_name}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\ifsc code.png' className='img-fluid' alt="" /><span>{settings[0]?.ifsc_code}</span>
                                </div>
                                <form onSubmit={Bankmethod}>

                                    <div className="uplo-img text-start">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn ' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>
                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }
                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" id='transaction-id2' aria-label="transaction-id" onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>

                                    <div className="update-profile mt-2 mb-0">
                                        <button className="text-white" type="submit" >{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>



                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Transaction;