import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useNavigate } from "react-router-dom";
import { Logindetail } from "../context/Logindetail.js";
import * as Yup from 'yup'
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { editkyc_fun, getprofile_fun, update_fun, } from "../api/Dashboardapi.js";
import { X } from "react-bootstrap-icons";

const Kyc = ({ settings }) => {

    const [spinner, setspinner] = useState(false)
    const [adharfile, setAdharfile] = useState([])
    const [panfile, setpanfile] = useState([])


    const { detail_info, setdetail_info } = useContext(Logindetail)

    const [initValue, setinitValue] = useState({
        "name_as_aadhar": `${detail_info?.name_as_aadhar ? detail_info?.name_as_aadhar : ''}`,
        "aadhar_number": `${detail_info?.aadhar_number ? detail_info?.aadhar_number : ''}`,
        "name_as_pan": `${detail_info?.name_as_pan ? detail_info?.name_as_pan : ''}`,
        "pan_number": `${detail_info?.pan_number ? detail_info?.pan_number : ''}`,
        "verified": detail_info?.verified ? detail_info?.verified : 1,
        "pan_documents": `${detail_info?.pan_documents ? detail_info?.pan_documents : ''}`,
        "aadhar_documents": `${detail_info?.aadhar_documents ? detail_info?.aadhar_documents : ''}`
    })

    const updateschema = Yup.object().shape({

        "name_as_aadhar": Yup.string()
            .required("Enter Name as per Aadhar")
            .matches(/^[a-zA-Z ]*$/, "Invalid Aadhar name"),

        "aadhar_number": Yup.string().required("Enter Aadhar Number")
            .min(12, ' Aadhar number Minimum 12 digits')
            .matches(/^[0-9]+$/, "Invalid  Aadhar number"),

        "name_as_pan": Yup.string().required("Enter Name as per PAN")
            .matches(/^[a-zA-Z ]*$/, "Invalid Pan name"),

        "pan_number": Yup.string().required("Enter PAN number")
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Pan number must contain 10 characters, including five uppercase letter,  four number, and last one uppercase letter"),

        "pan_documents": Yup.mixed(),

        "aadhar_documents": Yup.mixed(),

    })

    const handlegetprofile = () => {
        getprofile_fun()
            .then((res) => {
                localStorage.setItem("green_login", JSON.stringify(res?.data));
                setdetail_info(res?.data)
                // setfile(res?.data?.avatar);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const Formrik = useFormik({
        initialValues: initValue,
        validationSchema: updateschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
            const formdata = new FormData()
            formdata.append("name_as_aadhar", value.name_as_aadhar)
            formdata.append("aadhar_number", value.aadhar_number)
            formdata.append("name_as_pan", value.name_as_pan)
            formdata.append("pan_number", value.pan_number)
            // formdata.append("pan_documents[0]", pandocs1)
            // formdata.append("pan_documents[1]", pandocs2)
            // formdata.append("aadhar_documents[0]", aadhardocs1)
            // formdata.append("aadhar_documents[1]", aadhardocs2)
            adharfile?.map((data, i) => {
                formdata.append(`aadhar_documents[${i}]`, data)
            })
            panfile?.map((data, i) => { formdata.append(`pan_documents[${i}]`, data) })
            setspinner(true)
            update_fun(formdata)
                .then((res) => {
                    console.log(res)
                    toast.success('Kyc updated successfully')
                    // localStorage.setItem('green_login', JSON.stringify(res?.user))
                    // setdetail_info(res?.user)
                    handlegetprofile();
                    setspinner(false)
                })
                .catch((err) => {
                    console.log(err)
                    if (err?.response?.data?.status === 400) {
                        toast.error(err?.response?.data?.message);
                    }
                    setspinner(false)

                });
        }
    });

    // const handleaadhar = (e) => {
    //     setaadhardocs1(e.target.files[0])
    //     setaadhardocs2(e.target.files[1])
    // }

    // const handlepan = (e) => {
    //     setpandocs1(e.target.files[0])
    //     setpandocs2(e.target.files[1])
    // }


    const handleaadhar = (e) => {

        const data = Object.values(e.target.files)

        for (var i = 0; i < data?.length; i++) {

            if (data[i]?.type === 'image/jpg' || data[i]?.type === 'image/jpeg' || data[i]?.type === 'image/png') {

                if (data[i]?.size <= 2 * 1024 * 1024) {
                    setAdharfile([...adharfile, data[i]]);
                }
                else {
                    toast.error('File Size Max 2MB')
                }
            }
            else {
                toast.error('Check image format')
            }
        }

    }

    const cancelimage = (value) => {
        setAdharfile((p) => p?.filter(r => r != value))
    }

    const handlepan = (e) => {

        const data = Object.values(e.target.files)

        for (var i = 0; i < data?.length; i++) {

            if (data[i]?.type === 'image/jpg' || data[i]?.type === 'image/jpeg' || data[i]?.type === 'image/png') {

                if (data[i]?.size > 2 * 1024 * 1024 ||  e.target.files[0]?.size <= 0) {
                    toast.error('File Size Max 2MB')
                }
                else {
                 
                    setpanfile([...panfile, data[i]]);
                }
            }
            else {
                toast.error('Check image format')
            }
        }

    }

    const cancelimage2 = (value) => {
        setpanfile(panfile?.filter(e => e != value))
    }
    return (
        <>
            <HeadingBanner title={"KYC"} />
            <section>
                <div className="kyc-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4">
                                <Dashboard setting_data={settings} />
                            </div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="kyc-content">
                                    <div className="bd-heads pb-3" >
                                        <h4>KYC</h4>
                                    </div>
                                    <div className="bd-form border">
                                        <form onSubmit={Formrik.handleSubmit}>
                                            <div className="row">
                                                <div className="form-group col-md-6 col-sm-6 col-6 login-text">
                                                    <label>Name as Aadhar Card</label>
                                                    <input type="text" className="form-control" id="inputaddress" placeholder="Name as Aadhar Card" {...Formrik.getFieldProps("name_as_aadhar")} />
                                                    {
                                                        Formrik.touched.name_as_aadhar && Formrik.errors.name_as_aadhar ? (<p className="text-danger">{Formrik.errors.name_as_aadhar}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                                    <label>Name as Pan Card</label>
                                                    <input type="text" className="form-control" id="inputaddress2" placeholder="Name as Pan Card" {...Formrik.getFieldProps("name_as_pan")} />
                                                    {
                                                        Formrik.touched.name_as_pan && Formrik.errors.name_as_pan ? (<p className="text-danger m-0">{Formrik.errors.name_as_pan}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6  login-text">
                                                    <label>Aadhar Number</label>
                                                    <input type="text" className="form-control" id="inputaddress2" placeholder="Aadhar Number"  {...Formrik.getFieldProps("aadhar_number")} />
                                                    {
                                                        Formrik.touched.aadhar_number && Formrik.errors.aadhar_number ? (<p className="text-danger m-0">{Formrik.errors.aadhar_number}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6 login-text">
                                                    <label>Pan Number</label>
                                                    <input type="text" className="form-control" id="inputaddress2" placeholder="Pan Number" {...Formrik.getFieldProps("pan_number")} />
                                                    {
                                                        Formrik.touched.pan_number && Formrik.errors.pan_number ? (<p className="text-danger m-0">{Formrik.errors.pan_number}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group mb-3 col-md-6 col-12 login-text">
                                                    <label>Upload Aadhar Documents  <span className="text-danger doc-format">*(PNG,JPG,JPEG)</span></label>
                                                    <div className="upload-docs">
                                                        <button className="btn" type="button" onClick={() => { document.getElementById('aadharfile').click() }}> <span><img src="\assets\iocns\upload.png" alt="" /></span> Browse</button>
                                                    </div>
                                                    <input className="form-control d-none" id="aadharfile" type="file" accept="image/jpg, image/jpeg, image/png" multiple="multiple" onChange={handleaadhar} />
                                                    {
                                                        Formrik.touched.aadhar_documents && Formrik.errors.aadhar_documents ? (<p className="text-danger m-0">{Formrik.errors.aadhar_documents}</p>) : null
                                                    }

                                                    <div className="selected-file d-flex flex-wrap login-text">
                                                        {
                                                            adharfile?.map((data, i) => {
                                                                return (
                                                                    <div className="position-relative">
                                                                        <img src={URL.createObjectURL(data)} className="img-fluid docimg" alt="" />
                                                                        <div className="diselect position-absolute" onClick={() => { cancelimage(data) }} ><X /></div>
                                                                   
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>

                                                <div className="form-group mb-3 col-md-6 col-12 login-text">
                                                    <label>Upload Pan Documents <span className="text-danger doc-format">*(PNG,JPG,JPEG)</span></label>
                                                    <div className="upload-docs">
                                                        <button className="btn" type="button" onClick={() => { document.getElementById('panfile').click() }}> <span><img src="\assets\iocns\upload.png" alt="" /></span> Browse</button>
                                                    </div>
                                                    <input className="form-control d-none" id="panfile" accept="image/jpg, image/jpeg, image/png" multiple="multiple" type="file" onChange={handlepan} />
                                                    {
                                                        Formrik.touched.pan_documents && Formrik.errors.pan_documents ? (<p className="text-danger m-0">{Formrik.errors.pan_documents}</p>) : null
                                                    }
                                                    <div className="selected-file d-flex flex-wrap login-text">
                                                        {
                                                            panfile?.map((data, i) => {
                                                                return (
                                                                    <div className="position-relative">
                                                                        <img src={URL.createObjectURL(data)} className="img-fluid docimg" alt="" />
                                                                        <div className="diselect position-absolute" onClick={() => { cancelimage2(data) }} ><X /></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="update-profile  mx-3">
                                                <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Edit'}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Kyc;