import axios from 'axios'
import React, { useEffect, useState, useCallback, useContext, useRef } from 'react'
import { Enroll_fun, fetchBeneficiaries, getSavingPlan_fun, postSchemaSave } from '../api/Pageapi';
import useRazorpay from "react-razorpay";
import toast, { useToaster } from 'react-hot-toast';
import { Logindetail } from '../context/Logindetail';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import { Digitalscheme_fun, Getdigital_trans, GetPlanbenefit, Savedigitalplan } from '../api/RouteScheme2';
import { GoldPlant_fun, SaveGoldplan } from '../api/RouteScheme3';
import { Footerpages } from '../context/Footerpages';
import { schemeplans } from '../api/Controllapi';
import AOS from 'aos';
import { SchemeDetails } from '../context/SchemeDetails';
import { Arrow90degLeft, ArrowLeft, ArrowUp, ArrowUpShort, ChevronDown, X } from 'react-bootstrap-icons';




export default function Schemes({ setopenSchemes, setting_data }) {

    const router = useNavigate();

    const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;


    const { token, detail_info, setdetail_info } = useContext(Logindetail)
    const { setschemelist } = useContext(SchemeDetails)
    const [scheme_list, setscheme_list] = useState({});
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemData, setSchemaData] = useState()
    const [price, setprice] = useState();
    const [isLoading, setisLoading] = useState(true);
    const [benfic, setbenfic] = useState([]);
    const [DigitaScheme, setDegitalScheme] = useState({})
    const [Benifitid, setBenifitid] = useState()
    const [Goldplant_data, setGoldplant_data] = useState()
    const [checked, setchecked] = useState(false)
    const { footerpage } = useContext(Footerpages)
    const fromsubmit = useRef()
    const [ChargeAmt, setChargeAmt] = useState()
    const [benificid, setbenificid] = useState('')
    const [process_type, setprocess_type] = useState()
    const [clientamount, setclientamount] = useState()
    const [Transactionid, setTranscationid] = useState()
    const [transfile, settransfile] = useState()
    const [file1, setfile1] = useState()
    const [spinner, setspinner] = useState()
    const [Enroll_data, setEnroll_data] = useState()



    const logout = () => {
        localStorage.removeItem("green_login")
        localStorage.removeItem("green_token")
        localStorage.removeItem("kyc")
        router('/');
        setdetail_info();
    }

    const handlefectch = () => {
        fetchBeneficiaries()
            .then(res => {
                setbenfic(res?.data)
                setisLoading(false);
                if (res?.data?.status == 403) {
                    logout();
                }
            }).catch(err => {
                console.log('err', err.message);
                setisLoading(false)
            });
    }

    useEffect(() => {
        if (detail_info && token) {
            handlefectch()
        }
    }, [detail_info?.id])


    // 1 plan
    const handlePayment = useCallback(async () => {

        const options = {
            key: RAZAR_KEY,
            amount: (clientamount) * 100,
            currency: "INR",
            name: 'Greenheap',
            description: 'Greenheap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "amount": clientamount,
                    "saving_plan_id": shemData?.id,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': benificid,
                    "payment_method": 'razor_pay',
                }
                postSchemaSave(body, token).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })
            },

            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemData]);

    // 2 plan

    const handlePaymentDigital = useCallback(async () => {

        const options = {
            key: RAZAR_KEY,
            amount: (clientamount) * 100,
            currency: "INR",
            name: 'Greenheap',
            description: 'Greenheap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "digital_plan_id": shemData?.id,
                    "amount": clientamount,
                    "transaction_id": res?.razorpay_payment_id,
                    // 'benificiar': benificid,
                }
                Savedigitalplan(body).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })


            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemData]);

    // 3 plan
    const handlePaymentgoldplant = useCallback(async () => {
        const options = {
            key: RAZAR_KEY,
            amount: (ChargeAmt) * 100,
            currency: "INR",
            name: 'Greenheap',
            description: 'Greenheap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "gold_plant_id": shemData?.id,
                    "amount": clientamount,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': Benifitid,
                }
                SaveGoldplan(body).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                    setbenificid()
                }).catch(err => {
                    console.log('err', err.message)
                })
            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [ChargeAmt, shemData]);



    const handlepayModal = () => {
        if (shemData?.plan_title === "saving_plan") {
            handlePayment()
        }
        if (shemData?.plan_title === "digital_plan") {
            handlePaymentDigital()
        }
        if (shemData?.plan_title === "gold_plant") {
            handlePaymentgoldplant();
        }
    }



    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);

    const getscheme_plans = () => {
        schemeplans(detail_info?.id ? detail_info?.id : '')
            .then((res) => {
                setscheme_list(res?.data[0])
                setDegitalScheme(res?.data[1])
                setGoldplant_data(res?.data[2])
                setisLoading(false)
                setschemelist(res?.data)
            })
            .catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }


    useEffect(() => {
        getscheme_plans()
    }, [detail_info?.id])



    const paymentfun = (e) => {
        e.preventDefault()

        if (process_type == '1') {
            setShowRazorpayModal(true);
            handlepayModal();
            document.getElementById('payment-pup').click()
            document.getElementById('Rupees').value = null
        }
        else if (process_type == '2') {
            document.getElementById('payment-pup').click()
            document.getElementById('offlinepop-pup').click()
            document.getElementById('transaction-id').value = null
            setfile1()
            settransfile()
            setTranscationid()
        }
        else if (process_type == '3') {
            document.getElementById('payment-pup').click()
            document.getElementById('bankpop-pup').click()
            document.getElementById('transaction-id2').value = null
            setTranscationid()
        }
        else {
            toast.error('please select payment method')
        }

    }

    const upimethod = (e) => {
        e.preventDefault()

        if (shemData?.plan_title === 'saving_plan') {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('saving_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)

                postSchemaSave(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    setspinner(false)
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('offlinepop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

        if (shemData?.plan_title === "digital_plan") {

            if (Transactionid || transfile) {

                setspinner(true)
                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('digital_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)

                Savedigitalplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('offlinepop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

        if (shemData?.plan_title === "gold_plant") {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('gold_plant_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)

                SaveGoldplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('offlinepop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

    }

    const Bankmethod = (e) => {
        e.preventDefault()

        if (shemData?.plan_title === 'saving_plan') {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('saving_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'bank')
                formdata.append('uploads', transfile)

                postSchemaSave(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('bankpop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }
        if (shemData?.plan_title === "digital_plan") {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('digital_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'bank')
                formdata.append('uploads', transfile)

                Savedigitalplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('bankpop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }
        if (shemData?.plan_title === "gold_plant") {

            if (Transactionid || transfile) {

                setspinner(true)
                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('gold_plant_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'bank')
                formdata.append('uploads', transfile)

                SaveGoldplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('bankpop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

    }

    const screenshot = (e) => {
        setfile1(e.target.files[0]?.name)
        settransfile(e.target.files[0])
    }


    const getenroll = () => {
        Enroll_fun()
            .then((res) => {
                setEnroll_data(res?.data)
                setisLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }

    useEffect(() => {
        getenroll()
    }, [])

    return (<>
        <section>
            <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"> sssdsdsd</button>

            <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className={shemData?.plan_title === "gold_plant" ? 'modal-content paybox schemecard' : 'modal-content schemecard'}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name}</h1>

                            <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('Rupees').value = null; setprice(null) }} ></button>
                        </div>
                        <div className="modal-body">
                            <form ref={fromsubmit} onSubmit={(e) => {
                                e.preventDefault();

                                if (!price) {
                                    toast.error("Please Enter Price!")
                                } else if (shemData?.plan_title === "gold_plant") {
                                    const amount = parseFloat(price) + parseFloat(((price * 6 / 100) + (price * 3 / 100)))

                                    if (parseInt(price) < parseInt(shemData?.min_price)) {
                                        toast.error(`Minmum Amount is ${shemData?.min_price}!`)
                                    } else if (parseInt(price) > shemData?.max_price) {
                                        toast.error(`Maximum Amount is ${shemData?.max_price}!`)
                                    } else if (checked !== true) {
                                        toast.error('Please Agree Terms & conditions')
                                    }
                                    else {
                                        document.getElementById('closepriceModal')?.click();
                                        document.getElementById('payment-pup').click()
                                        document.getElementById('inlineRadio1').checked = false;
                                        document.getElementById('inlineRadio2').checked = false;
                                        document.getElementById('inlineRadio3').checked = false;
                                        document.getElementById('Rupees').value = null
                                        setprocess_type();
                                        setclientamount(price)
                                        setChargeAmt(amount)
                                        setBenifitid(benificid)

                                    }
                                } else {
                                    if (parseInt(price) < parseInt(shemData?.min_price)) {
                                        toast.error(`Minmum Amount is ${shemData?.min_price}!`)
                                    } else if (parseInt(price) > shemData?.max_price) {
                                        toast.error(`Maximum Amount is ${shemData?.max_price}!`)
                                    } else if (checked !== true) {
                                        toast.error('Please Agree Terms & conditions')
                                    }
                                    else {
                                        document.getElementById('closepriceModal')?.click();
                                        document.getElementById('payment-pup').click()
                                        document.getElementById('inlineRadio1').checked = false;
                                        document.getElementById('inlineRadio2').checked = false;
                                        document.getElementById('inlineRadio3').checked = false;
                                        setprocess_type();
                                        setclientamount(price)

                                    }
                                }

                            }}>

                                {
                                    shemData?.plan_title === "digital_plan" ? '' : (
                                        <div className="mb-3">
                                            <label className="col-form-label fw-bold">Beneficiaries</label>
                                            <select className="form-select p-3" onChange={(e) => { setbenificid(e.target.value) }} aria-label="ben" >
                                                <option value={''} selected>{detail_info?.name}</option>
                                                {
                                                    benfic.map((data, i) => (
                                                        <option value={data?.id} key={i}>{data.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    )
                                }
                                <div className="mb-3">
                                    <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${shemData?.min_price} to Maximum Amount ${shemData?.max_price})`}</small></label>
                                    <input type="text" maxLength={10} value={price} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${shemData?.min_price}`} />
                                    {/* {shemData?.plan_title === "gold_plant" && price ? <span className='text-color'>Deduct Gst & Service tax  : {(price - ((price * 6 / 100) + (price * 3 / 100))).toFixed(2)}</span> : ''} */}
                                    {shemData?.plan_title === "gold_plant" ? (<><p className='text-color m-0'>VAT charge : {price ? (price * 3 / 100).toFixed(2) : 0}</p>
                                        <p className='text-color m-0'>Service charge : {price ? (price * 6 / 100).toFixed(2) : 0}</p>
                                        <p className='text-color m-0'>VAT + Service  : {price ? ((price * 6 / 100) + (price * 3 / 100)).toFixed(2) : 0}</p>
                                        <p className='text-color m-0'>Total Amount   : {price ? (parseFloat(price) + ((price * 6 / 100) + (price * 3 / 100))) : 0}</p>
                                    </>) : ''}
                                </div>
                                <div className="mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                    <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                        {/* I  agree {
                                            footerpage ? (<a href={`pages/${footerpage[0]?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>) : (<span className='termsborder'>Terms and Condition</span>)
                                        } */}
                                        I agree <a href={`pages/${shemData?.page?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                    </label>
                                </div>


                                <div className="mb-3">
                                    <div className="update-profile  mx-2">
                                        <button className="text-white" type="submit">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                    <div>
                        <div data-aos="fade-right">
                            <img src='/assets/images/shpas.png' />
                        </div>
                        <div data-aos="fade-left">
                            <h2 className='fw-bold'>Quick overview of schemes</h2>
                        </div>
                    </div>
                </div>


                <section  >
                    <div className='container smallBan d-block d-sm-none'>
                        <div className='row  '>

                            {
                                isLoading ? Array.from({ length: 3 }).map((_, i) => {

                                    return (

                                        <div className='col-4'>
                                            <Shimmer className='rounded w-100' height={120} />
                                        </div>
                                    )

                                }) : Enroll_data ? Enroll_data.map((data) => {
                                    return (
                                        <div className='col-4' data-aos="flip-left">
                                            <img alt='' src={data?.image} className='' />
                                        </div>
                                    )
                                }) : ''
                            }

                            {/* 
            <div className='col-lg-3 col-md-4 col-sm-6  col-6 mb-2 '>
              <img alt='' src='/assets/images/bb.png' className='' />
            </div> */}
                        </div>
                    </div>
                </section>

                {isLoading ?
                    <div className=' row '>
                        {
                            Array.from({ length: 3 }).map((_, i) => (
                                <div className='col-md-6  col-lg-4 col-md-6 col-sm-12 mt-lg-0 mt-sm-3' key={i}>
                                    <div className='d-sm-flex justify-content-sm-center d-md-block  justify-content-md-center '>
                                        <div className='schema_main  '>
                                            <div className='top'>
                                                <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                                <h4 className=' fw-bold pb-2'> <Shimmer width={180} height={30} /></h4>
                                            </div>
                                            <div className='schema'>
                                                <div className='d-flex gap-2 align-items-center mb-3'>
                                                    {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                    <Shimmer width={250} height={22} className='rounded' />
                                                </div>
                                                <div className='d-flex gap-2 align-items-center mb-3'>
                                                    {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                    <Shimmer width={250} height={22} className='rounded' />
                                                </div>
                                                <div className='d-flex gap-2 align-items-center mb-3'>
                                                    {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                    <Shimmer width={250} height={22} className='rounded' />
                                                </div>
                                                <div className='d-flex gap-2 align-items-center mb-3'>
                                                    {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                    <Shimmer width={250} height={22} className='rounded' />
                                                </div>
                                                <div className='d-flex gap-2 align-items-center mb-3 '>
                                                    {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                    <Shimmer width={250} height={22} className='rounded' />
                                                </div>

                                                <div className='schemebtn-3'>
                                                    <Shimmer width={177} height={49} className='rounded-5' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div className=' row '>
                        {
                            scheme_list ?
                                (<>
                                    <div className=' d-none  d-sm-block col-md-6 col-lg-4 col-sm-12 chkclick ' >
                                        <div data-aos="fade-up" className='scheme-mbbox'>
                                            <div className='schema_main'  >
                                                <div className='top_active act1'>
                                                    <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                                    <h4 className=' fw-bold pb-2'>{scheme_list?.name}</h4>
                                                </div>
                                                <div className='schema schemelen'>
                                                    {/* <div className=''>
                                                    <p className='mb-0'  dangerouslySetInnerHTML={{__html: data?.description }}></p>
                                                </div> */}
                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Lock in Period </p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>{scheme_list?.plan_tenure ?? 0} Months</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Punctuality Bonus</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>{scheme_list?.punctuality_bonus ?? 0} %</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Profit Rate </p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>{scheme_list?.bonus ?? 0} %</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Minimum</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>₹ {scheme_list?.min_price ?? 0}</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Maximum</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>₹ {scheme_list?.max_price ?? 0}</p>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className='schema schemebtn-1'>
                                                    <button type='button' onClick={() => {
                                                        if (detail_info && token) {
                                                            setSchemaData(scheme_list);
                                                            setprice();
                                                            document.getElementById('agreecheck').checked = false;
                                                            document.getElementById("openPriceplanpopu")?.click();
                                                            setchecked(false)

                                                        } else {
                                                            setSchemaData(scheme_list);
                                                            setprice();
                                                            document.getElementById("loginPopupopen")?.click();
                                                            document.getElementById('agreecheck').checked = false;
                                                            setchecked(false)
                                                            setopenSchemes(true);

                                                        }
                                                    }} className='btn d-flex align-items-center gap-2'>Buy scheme <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* mobile-view */}

                                    <div className='d-block mb-scheme-box  d-sm-none my-2'>
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item mb-schem">
                                                <h2 class="accordion-header mb-schem-head" id="flush-headingOne">
                                                    <div class="accordion-button collapsed d-block" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        <div className='mb-sch-img'>
                                                            <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                                        </div>
                                                        <div className='mb-sch-name d-flex align-items-center justify-content-between'>
                                                            <h5 className=' fw-bold pb-2'>{scheme_list?.name}</h5>
                                                            <h6><ChevronDown /></h6>
                                                        </div>
                                                    </div>
                                                </h2>
                                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className='schema schemelen'>
                                                        {/* <div className=''>
                                                    <p className='mb-0'  dangerouslySetInnerHTML={{__html: data?.description }}></p>
                                                </div> */}
                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Lock in Period </p>
                                                            </div>

                                                            <div>
                                                                <p className='mb-0'>{scheme_list?.plan_tenure ?? 0} Months</p>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Punctuality Bonus</p>
                                                            </div>

                                                            <div>
                                                                <p className='mb-0'>{scheme_list?.punctuality_bonus ?? 0} %</p>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Profit Rate </p>
                                                            </div>

                                                            <div>
                                                                <p className='mb-0'>{scheme_list?.bonus ?? 0} %</p>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Minimum</p>
                                                            </div>

                                                            <div>
                                                                <p className='mb-0'>₹ {scheme_list?.min_price ?? 0}</p>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Maximum</p>
                                                            </div>

                                                            <div>
                                                                <p className='mb-0'>₹ {scheme_list?.max_price ?? 0}</p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className='schema schemebtn-1'>
                                                        <button type='button' onClick={() => {
                                                            if (detail_info && token) {
                                                                setSchemaData(scheme_list);
                                                                setprice();
                                                                document.getElementById('agreecheck').checked = false;
                                                                document.getElementById("openPriceplanpopu")?.click();
                                                                setchecked(false)

                                                            } else {
                                                                setSchemaData(scheme_list);
                                                                setprice();
                                                                document.getElementById("loginPopupopen")?.click();
                                                                document.getElementById('agreecheck').checked = false;
                                                                setchecked(false)
                                                                setopenSchemes(true);

                                                            }
                                                        }} className='btn d-flex align-items-center gap-2'>Buy scheme <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                                : ""
                        }

                        {
                            DigitaScheme ? (
                                <>
                                    <div className=' d-none  d-sm-block my-sm-4 my-md-0 col-md-6 col-lg-4 col-sm-12 chkclick fade-up' >
                                        <div data-aos="fade-up" className='scheme-mbbox'>
                                            <div className='schema_main'>
                                                <div className='top_active'>
                                                    <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                                    <h4 className=' fw-bold pb-2'>{DigitaScheme?.name}</h4>
                                                </div>
                                                <div className='schema schemelen'>
                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Lock in Period</p>
                                                        </div>

                                                        <div>
                                                            {/* <p className='mb-0'>{DigitaScheme?.gold_approx ?? 0} %</p> */}
                                                            <p className='mb-0'>3 Years</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Active Plan</p>
                                                        </div>

                                                        <div>
                                                            {/* <p className='mb-0'>{parseFloat(DigitaScheme?.silver_bonus ?? 0)} %</p> */}
                                                            <p className='mb-0'>1 (gm)</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Minimum</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>₹ {DigitaScheme?.min_price ?? 0}</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Maximum</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>₹ {DigitaScheme?.max_price ?? 0}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=' schema schemebtn-1'>
                                                    <button type='button' onClick={() => {
                                                        if (!detail_info && !token) {
                                                            setSchemaData(DigitaScheme);
                                                            setchecked(false)
                                                            document.getElementById('agreecheck').checked = false;
                                                            document.getElementById("loginPopupopen")?.click();

                                                        }
                                                        else if (DigitaScheme?.cancel_status === 0) {
                                                            toast.error('This Scheme already purchased')
                                                        }
                                                        else {
                                                            setSchemaData(DigitaScheme);
                                                            setchecked(false);
                                                            document.getElementById('agreecheck').checked = false;
                                                            document.getElementById("openPriceplanpopu")?.click();
                                                        }
                                                    }}

                                                        className='btn d-flex align-items-center gap-2'>Buy scheme  <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* mobile-view */}

                                    <div className='d-block mb-scheme-box  d-sm-none my-2'>
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item  mb-schem">
                                                <h2 class="accordion-header mb-schem-head" id="flush-headingOne">
                                                    <div class="accordion-button collapsed d-block" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        <div className='mb-sch-img'>
                                                            <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                                        </div>
                                                        <div className='mb-sch-name d-flex align-items-center justify-content-between'>
                                                            <h5 className=' fw-bold pb-2'>{DigitaScheme?.name}</h5>
                                                            <h6><ChevronDown /></h6>
                                                        </div>
                                                    </div>
                                                </h2>
                                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className='schema schemelen'>
                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Lock in Period</p>
                                                            </div>

                                                            <div>
                                                                {/* <p className='mb-0'>{DigitaScheme?.gold_approx ?? 0} %</p> */}
                                                                <p className='mb-0'>3 Years</p>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Active Plan</p>
                                                            </div>

                                                            <div>
                                                                {/* <p className='mb-0'>{parseFloat(DigitaScheme?.silver_bonus ?? 0)} %</p> */}
                                                                <p className='mb-0'>1 (gm)</p>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Minimum</p>
                                                            </div>

                                                            <div>
                                                                <p className='mb-0'>₹ {DigitaScheme?.min_price ?? 0}</p>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                            <div className="d-flex gap-2  align-items-center">
                                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                                <p className='mb-0'>Maximum</p>
                                                            </div>

                                                            <div>
                                                                <p className='mb-0'>₹ {DigitaScheme?.max_price ?? 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className=' schema schemebtn-1'>
                                                        <button type='button' onClick={() => {
                                                            if (!detail_info && !token) {
                                                                setSchemaData(DigitaScheme);
                                                                setchecked(false)
                                                                document.getElementById('agreecheck').checked = false;
                                                                document.getElementById("loginPopupopen")?.click();

                                                            }
                                                            else if (DigitaScheme?.cancel_status === 0) {
                                                                toast.error('This Scheme already purchased')
                                                            }
                                                            else {
                                                                setSchemaData(DigitaScheme);
                                                                setchecked(false);
                                                                document.getElementById('agreecheck').checked = false;
                                                                document.getElementById("openPriceplanpopu")?.click();
                                                            }
                                                        }}

                                                            className='btn d-flex align-items-center gap-2'>Buy scheme  <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                                : ''
                        }

                        {
                            Goldplant_data ? (<>
                                <div className=' d-none mt-md-4 mt-sm-0 mt-lg-0  d-sm-block  col-md-6 col-lg-4 col-sm-12 chkclick fade-up' >
                                    <div data-aos="fade-up" className='scheme-mbbox'>
                                        <div className='schema_main'>
                                            <div className='top_active'>
                                                <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                                <h4 className=' fw-bold pb-2'>{Goldplant_data?.name}</h4>
                                            </div>
                                            <div className='schema schemelen'>
                                                <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                    <div className="d-flex gap-2  align-items-center">
                                                        <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                        <p className='mb-0'>Principal Returns After</p>
                                                    </div>

                                                    <div>
                                                        <p className='mb-0'>{Goldplant_data?.returns_year ?? 0} year</p>
                                                    </div>
                                                </div>

                                                <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                    <div className="d-flex gap-2  align-items-center">
                                                        <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                        <p className='mb-0'>Assured Returns</p>
                                                    </div>

                                                    <div>
                                                        <p className='mb-0'>{Goldplant_data?.payout_term ?? 0} year</p>
                                                    </div>
                                                </div>

                                                <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                    <div className="d-flex gap-2  align-items-center">
                                                        <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                        <p className='mb-0'>Minimum</p>
                                                    </div>

                                                    <div>
                                                        <p className='mb-0'>₹ {Goldplant_data?.min_price ?? 0}</p>
                                                    </div>
                                                </div>

                                                <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                    <div className="d-flex gap-2  align-items-center">
                                                        <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                        <p className='mb-0'>Maximum</p>
                                                    </div>

                                                    <div>
                                                        <p className='mb-0'>₹ {Goldplant_data?.max_price ?? 0}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=' schema schemebtn-1'>
                                                <button type='button' onClick={() => {
                                                    if (detail_info && token) {
                                                        setSchemaData(Goldplant_data);
                                                        document.getElementById('agreecheck').checked = false;
                                                        setchecked(false)
                                                        document.getElementById("openPriceplanpopu")?.click();
                                                    } else {
                                                        setSchemaData(Goldplant_data);
                                                        document.getElementById('agreecheck').checked = false;
                                                        setchecked(false)
                                                        document.getElementById("loginPopupopen")?.click();
                                                        setopenSchemes(true);
                                                    }
                                                }} className='btn d-flex align-items-center gap-2'>Buy scheme  <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* mobile-view */}

                                <div className='d-block mb-scheme-box  d-sm-none my-2'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item mb-schem">
                                            <h2 class="accordion-header mb-schem-head" id="flush-headingOne">
                                                <div class="accordion-button collapsed d-block" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    <div className='mb-sch-img'>
                                                        <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                                    </div>
                                                    <div className='mb-sch-name d-flex align-items-center justify-content-between'>
                                                        <h5 className=' fw-bold pb-2'>{Goldplant_data?.name}</h5>
                                                        <h6><ChevronDown /></h6>
                                                    </div>
                                                </div>
                                            </h2>
                                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className='schema schemelen'>
                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Principal Returns After</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>{Goldplant_data?.returns_year ?? 0} year</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Assured Returns</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>{Goldplant_data?.payout_term ?? 0} year</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Minimum</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>₹ {Goldplant_data?.min_price ?? 0}</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                        <div className="d-flex gap-2  align-items-center">
                                                            <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                            <p className='mb-0'>Maximum</p>
                                                        </div>

                                                        <div>
                                                            <p className='mb-0'>₹ {Goldplant_data?.max_price ?? 0}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=' schema schemebtn-1'>
                                                    <button type='button' onClick={() => {
                                                        if (detail_info && token) {
                                                            setSchemaData(Goldplant_data);
                                                            document.getElementById('agreecheck').checked = false;
                                                            setchecked(false)
                                                            document.getElementById("openPriceplanpopu")?.click();
                                                        } else {
                                                            setSchemaData(Goldplant_data);
                                                            document.getElementById('agreecheck').checked = false;
                                                            setchecked(false)
                                                            document.getElementById("loginPopupopen")?.click();
                                                            setopenSchemes(true);
                                                        }
                                                    }} className='btn d-flex align-items-center gap-2'>Buy scheme  <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>) : ''
                        }
                    </div>
                }
            </div>
        </section>


        <section className='schema2' >
            <div className='container'>
                <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                    <div>
                        <div data-aos="fade-up-right">
                            <img src='/assets/images/shpas.png' alt='' />
                        </div>
                        <div data-aos="fade-up-left">
                            <h2 className='fw-bold mb-0'>Returns with Gold Investment Leaf </h2>
                            <h2 className='fw-bold mb-4'>(SIP Scheme)</h2>
                        </div>
                    </div>
                </div>
                <div className='mb-3'>
                    <div data-aos="fade-up">
                        <div className='d-flex schematable gap-2'>
                            <div>
                                <div className='mb-2 leaft leaft-head text-center G1'>
                                    <h5 className='text-light'>Gold Investment Leaf Scheme</h5>
                                </div>

                                <div className='mb-2 leaft text-center  G2'>
                                    <h5>3 months (0-90 days)</h5>
                                </div>

                                <div className='mb-2 leaft text-center G3'>
                                    <h5>6 months (90-180 days)</h5>
                                </div>

                                <div className='mb-2 leaft text-center G4'>
                                    <h5>9 months (180 - 270 days)</h5>
                                </div>

                                <div className='mb-2 leaft text-center G5'>
                                    <h5>12 months (270 - 360 days)</h5>
                                </div>

                                <div className='mb-2 leaft text-center G6'>
                                    <h5>2nd Year</h5>
                                </div>

                                <div className='mb-2 leaft text-center G7'>
                                    <h5>3rd Year</h5>
                                </div>
                            </div>

                            <div>
                                <div className='mb-2 leaft  leaft-head  text-center C1'>
                                    <h5>GreenHeap Gold approx. (1,000 assured)</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2 '>
                                    <h5>(0.25% x 3m) Rs. 7.50</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(0.5% X 6m) Rs. 30.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(0.75% X 9m) Rs. 67.50</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(1% X 12m) Rs. 120.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(1% X 24m) Rs. 240.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(1% X 36m) Rs. 360.00</h5>
                                </div>
                            </div>

                            <div>
                                <div className='mb-2 leaft  leaft-head text-center C1'>
                                    <h5>GreenHeap Silver Bonus</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(9.6% per annum For 3m) Rs. 24.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(10% per annum For 6m) Rs. 50.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(12% per annum For 9m) Rs. 90.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(14% per annum For 12m) Rs. 140.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(18% per annum For 24m) Rs. 360.00</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(20% per annum For 36m) Rs. 600.00</h5>
                                </div>
                            </div>

                            <div>
                                <div className='mb-2 leaft  leaft-head text-center C1'>
                                    <h5>GreenHeap Gold + Silver Bonus</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(7.50 + 24.00) Rs. 31.5</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(30.00 + 50.00) Rs. 80</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(67.50 + 90.00) Rs. 157.5</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(120.00 + 140.00) Rs. 260</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(240.00 + 360.00) Rs. 600</h5>
                                </div>

                                <div className='mb-2 leaft text-center C2'>
                                    <h5>(360.00 + 600.00) Rs. 960</h5>
                                </div>
                            </div>

                            <div>
                                <div className='mb-2 leaft text-center px-lg-1 leaft-head F1'>
                                    <h5 className='text-light m-0'>Sip Total Returns</h5>
                                    <h5 className='text-light'>(Cash Back)</h5>
                                </div>

                                <div className='mb-2 leaft text-center F2'>
                                    <h5>31.5</h5>
                                </div>

                                <div className='mb-2 leaft text-center F3'>
                                    <h5>80</h5>
                                </div>

                                <div className='mb-2 leaft text-center F4'>
                                    <h5>157.5</h5>
                                </div>

                                <div className='mb-2 leaft text-center F5'>
                                    <h5>260</h5>
                                </div>

                                <div className='mb-2 leaft text-center F6'>
                                    <h5>600</h5>
                                </div>

                                <div className='mb-2 leaft text-center F7'>
                                    <h5>960</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div data-aos="fade-left">
                    <div className='text-center'>
                        <h5 className='terms-text'>
                            <span className='' style={{ cursor: "pointer" }} onClick={() => { router(`/pages/${footerpage[0]?.slug}`) }}>*Terms & Conditions are apply
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </section>

        {/* qr-code-modal */}

        <section>
            <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#offlinebox" id='offlinepop-pup'>
                Launch demo modal
            </button>
            <div className="modal fade" id="offlinebox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className='modal-content'>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-2' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('offlinepop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name}</h1>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('offlinepop-pup').click() }} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="qr-detail text-center">
                                <h6>{setting_data ? setting_data[0]?.account_holder : ''}</h6>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className="qr-upiid text-center">
                                    <h6 className='mb-0'>Vyapar.171813426519@hdfcbank</h6>
                                </div>
                            </div>
                            <div className="qq-sacn-img p-2">
                                <img src={setting_data ? setting_data[0]?.qr_code : ''} alt='' className='img-fluid' />
                            </div>
                            <form onSubmit={upimethod}>
                                <div className="uplo-img ">
                                    <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                    <button className='btn' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>

                                    {
                                        file1 ? (
                                            <>
                                                <div className='uploadfile-name position-relative'>
                                                    <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                    <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                </div>
                                            </>
                                        ) : ''
                                    }

                                    <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" aria-label="transaction-id" id='transaction-id' onChange={(e) => { setTranscationid(e.target.value) }} />
                                    <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                </div>
                                <div className="update-profile">
                                    <button className="text-white" type="submit">{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        {/* payment-modal */}

        <section>
            <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#paymentbox" id='payment-pup'>
                Launch demo modal
            </button>
            <div className="modal fade" id="paymentbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className='modal-content'>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('payment-pup').click() }} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="pm-ti">
                                <h5> Choose Payment Method</h5>
                            </div>
                            <div className="pm-choice ">
                                <form onSubmit={paymentfun}>
                                    <div className={process_type == '1' ? 'form-check act' : 'form-check'}>
                                        <div className="selecttypes p-2 d-flex align-items-center gap-2">
                                            <input class="form-check-input mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" onChange={(e) => { setprocess_type(e.target.value) }} />
                                            <label class="form-check-label" for="inlineRadio1">Online Razarpay</label>
                                            <div className='razoricon'><img src='\assets\images\razar_icon.png' className='img-fluid' alt='' /></div>
                                        </div>
                                    </div>
                                    <div className={process_type == '2' ? 'form-check act' : 'form-check'} >
                                        <div className="selecttypes p-2  d-flex align-items-center gap-2">
                                            <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" onChange={(e) => { setprocess_type(e.target.value) }} />
                                            <label class="form-check-label" for="inlineRadio2">QR code / UPI</label>
                                            <div className='razoricon'><img src='\assets\images\upi.png' className='img-fluid' alt='' /></div>
                                        </div>
                                    </div>
                                    <div className={process_type == '3' ? 'form-check act' : 'form-check'}>
                                        <div className="selecttypes py-3 px-2  d-flex align-items-center gap-2">
                                            <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="3" onChange={(e) => { setprocess_type(e.target.value) }} />
                                            <label class="form-check-label" for="inlineRadio3">Bank Details</label>
                                            <div className='banic'><img src='assets\iocns\bank.png' className='img-fluid' alt='' /></div>
                                        </div>
                                    </div>
                                    <div className="update-profile ">
                                        <button className="text-white" type="submit">Continue</button>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>

        {/* bandetail-modal */}

        <section>
            <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#bankbox" id='bankpop-pup'>
                Launch demo modal
            </button>
            <div className="modal fade" id="bankbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className='modal-content'>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-1' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('bankpop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span> {shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name} </h1>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('bankpop-pup').click() }} ></button>
                        </div>
                        <div className="modal-body ac-holdbank">
                            <h5 className='mb-4'>Bank Details</h5>

                            <div className="acc-holder">
                                <img src='assets\iocns\Profile.png' className='img-fluid' alt='' /><span>{setting_data ? setting_data[0]?.account_holder : ''}</span>
                            </div>
                            <div className="acc-holder">
                                <img src='assets\iocns\accountnumber.png' className='img-fluid' alt='' /><span>{setting_data ? setting_data[0]?.account_number : ''}</span>
                            </div>
                            <div className="acc-holder">
                                <img src='assets\iocns\bank.png' className='img-fluid' alt='' /><span>{setting_data ? setting_data[0]?.bank_name : ''}</span>
                            </div>
                            <div className="acc-holder">
                                <img src='assets\iocns\branchlocatin.png' className='img-fluid' alt='' /><span>{setting_data ? setting_data[0]?.branch_name : ''}</span>
                            </div>
                            <div className="acc-holder">
                                <img src='\assets\iocns\ifsc code.png' className='img-fluid' alt='' /><span>{setting_data ? setting_data[0]?.ifsc_code : ''}</span>
                            </div>
                            <form onSubmit={Bankmethod}>

                                <div className="uplo-img text-start">
                                    <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                    <button className='btn ' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>
                                    {
                                        file1 ? (
                                            <>
                                                <div className='uploadfile-name position-relative'>
                                                    <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                    <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                </div>
                                            </>
                                        ) : ''
                                    }
                                    <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" id='transaction-id2' aria-label="transaction-id" onChange={(e) => { setTranscationid(e.target.value) }} />
                                    <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                </div>

                                <div className="update-profile mt-2 mb-0">
                                    <button className="text-white" type="submit" >{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                </div>
                            </form>



                        </div>

                    </div>
                </div>
            </div>
        </section>

    </>
    )
}

