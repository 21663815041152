import React, { useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner";
import Dashboard from "../Components/Dashboard";
import { getCommisionApi } from "../api/Dashboardapi";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import moment from "moment";


const Commission = ({ settings }) => {

    const [data, setData] = useState([])
    const [isLoading, setisLoading] = useState(false)

    const fetchCommision = () => {
        setisLoading(true)
        getCommisionApi().then(res => {
            setisLoading(false)
            setData(res.data);
        }).catch(err => {
            console.log("err", err.message);
            setisLoading(false)
        })
    }

    useEffect(() => {
        fetchCommision();
    }, [])

    const paginationModel = { page: 0, pageSize: 5 };

    const columns = [
        { field: 's_no', headerName: 'S.N0', width: 70 },

        {

            field: 'user_name',
            headerName: 'NAME',
            width: 130,
            // valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
            sortable: false,
        },

        {
            field: 'plan_name',
            headerName: 'PLAN',
            width: 270,
            sortable: false,
        },

        {
            field: 'transaction_id',
            headerName: 'TRANSCATION',
            width: 200,
            sortable: false,
        },

        {
            field: 'created_at',
            headerName: 'DATE',
            width: 140,
            valueGetter: (value, row) => moment(row.created_at).format('DD-MM-YYYY') ?? '-',
        },

        {
            field: 'commission',
            headerName: 'COMMISSION',
            width: 150,
            valueGetter: (value, row) => row?.commission ? `₹${row?.commission}` : '-',
            sortable: false,
        },

        // {
        //     field: 'status',
        //     headerName: 'status',
        //     valueGetter: (value, row) => row.status ? row.status : "UNPAID" ,
        //     sortable: false,
        // },

    ]



    return (<>
        <HeadingBanner title={"Agent Commission"} />
        <section>
            <div className="container">
                <div className="siptrans-layout">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="siptrans-content position-relative">
                                {/* <div className="siptrans-head pb-3"></div> */}

                                <div className="siptrans-head pb-3 align-items-center d-flex justify-content-between">
                                    <h4>Commission</h4>
                                </div>

                                {
                                    isLoading ? (
                                        <div className="tableload  text-center position-absolute">
                                            <div class="spinner-border text-primary text-center" role="status"></div>
                                        </div>
                                    ) : data.length > 0 ? ((
                                        <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                            <DataGrid
                                                rows={data.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                columns={columns}
                                                disableColumnMenu
                                                initialState={{ pagination: { paginationModel } }}
                                                pageSizeOptions={[5, 10]}
                                                sx={{ border: 0, marginTop: 3 }}
                                                disableColumnFilter
                                                disableColumnSelector
                                                disableDensitySelector
                                                className="schemedata-table"
                                                slots={{ toolbar: GridToolbar }}
                                                slotProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                        printOptions: { disableToolbarButton: true },
                                                        csvOptions: { disableToolbarButton: true },
                                                        ShowCellToolTip: false
                                                    },
                                                }}
                                            />
                                        </Paper>
                                    )) : <div className="text-center no-commision"> 
                                        <img src="\assets\noData.png" className="img-fluid" alt=""/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default Commission