import React, { useEffect } from 'react'
import HeadingBanner from '../Components/HeadingBanner.jsx'
import Schemes from '../Components/Schemes.jsx'

export default function Sceme({setopenScheme , setting_datas}) {


  return (
    <>
     <HeadingBanner title={"GreenHeap Scheme"} />
     <Schemes setopenSchemes={setopenScheme} setting_data={setting_datas}  />
    </>
  )
}
