import React, { useEffect, useState } from 'react'
import { getfaq_fun } from '../api/Pageapi'
import { Shimmer } from 'react-shimmer'
import AOS from 'aos'




export default function FAQ({setting_data}) {



    const [faqdata, setfaqdata] = useState([])
    const [Isloading, setIsloading] = useState(true)

    const FAq_List = (id) => {
        setIsloading(true)

        getfaq_fun(id)
            .then((response) => {
                setfaqdata(response?.data)
                setIsloading(false)

            })
            .catch((err) => {
                console.log(err)
                setIsloading(false)
            })
    }

    useEffect(() => {
        FAq_List(1)
    }, [])
    useEffect(() => {
        AOS.init({
          duration : 1500
        });
      }, []);





    return (
        <div className='gaq-B'>
            <div className='container'>
                <div className='d-flex justify-content-center w-100 text-center titsksd mb-4'>
                    <div data-aos="fade-right">
                        <img src='/assets/images/shpas.png' />
                        <h2 className='fw-bold'>Frequently Asked Questions</h2>
                    </div>
                </div>


                <div className="accordion row" id="accordionPanelsStayOpenExample">

                    <div data-aos="fade-left">
                        <ul class="nav nav-pills scheme-qus mb-5 justify-content-center" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active schemebtn" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => { FAq_List(1) }}>{setting_data ? setting_data[0]?.saving_plan : 'Loading...'}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link schemebtn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => { FAq_List(2) }}>{setting_data ? setting_data[0]?.digital_plan : 'Loading...'}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link schemebtn" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => { FAq_List(3) }}>{setting_data ? setting_data[0]?.gold_plant : 'Loading...'}</button>
                            </li>
                        </ul>
                    </div>
                    <div data-aos="fade-up">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row">
                                    {

                                        Isloading ? Array.from({ length: 6 }).map((_, i) => {
                                            return (
                                                // <div className='col-sm-6 mb-3' key={i}>
                                                //     <div><Shimmer height={64} className='rounded w-100' /></div>
                                                // </div>
                                                <div className='col-md-6 col-sm-12 mb-3' key={i}>
                                                <div className="accordion-item ">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${i}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                        <Shimmer height={20} width={350} className='rounded ' />
                                                        </button>
                                                    </h2>
                                                    <div id={i} className="accordion-collapse collapse">
                                                        <div className="accordion-body">
                                                            <p className='four_line mb-2'><Shimmer height={10} width={350} className='rounded ' /></p>
                                                            <p className='four_line mb-2'><Shimmer height={10} width={350} className='rounded ' /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }) :

                                            faqdata ? faqdata.map((r, i) => {
                                                return (
                                                    <>

                                                        <div className='col-md-6 col-sm-12 mb-3' key={i}>

                                                            <div className="accordion-item ">
                                                                <h2 className="accordion-header">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${r.id}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                                        {r.title}
                                                                    </button>
                                                                </h2>
                                                                <div id={r.id} className="accordion-collapse collapse">
                                                                    <div className="accordion-body">
                                                                        <p className='four_line'>{r.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                                )
                                            }) : ""
                                    }
                                </div>

                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row">
                                    {

                                        Isloading ? Array.from({ length: 6 }).map((_, i) => {
                                            return (
                                                // <div className='col-sm-6 mb-3' key={i}>
                                                //     <div><Shimmer height={64} className='rounded w-100' /></div>
                                                // </div>
                                                <div className='col-md-6 col-sm-12 mb-3' key={i}>
                                                <div className="accordion-item ">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${i}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                        <Shimmer height={20} width={350} className='rounded ' />
                                                        </button>
                                                    </h2>
                                                    <div id={i} className="accordion-collapse collapse">
                                                        <div className="accordion-body">
                                                            <p className='four_line mb-2'><Shimmer height={10} width={350} className='rounded ' /></p>
                                                            <p className='four_line mb-2'><Shimmer height={10} width={350} className='rounded ' /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }) :

                                            faqdata ? faqdata.map((r, i) => {
                                                return (
                                                    <>

                                                        <div className='col-md-6 col-sm-12 mb-3' key={i}>
                                                            <div className="accordion-item ">
                                                                <h2 className="accordion-header">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${r.id}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                                        {r.title}
                                                                    </button>
                                                                </h2>
                                                                <div id={r.id} className="accordion-collapse collapse">
                                                                    <div className="accordion-body">
                                                                        <p className='four_line'>{r.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                                )
                                            }) : ""
                                    }
                                </div>
                            </div>
                            
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="row">
                                    {

                                        Isloading ? Array.from({ length: 6 }).map((_, i) => {
                                            return (
                                                // <div className='col-sm-6 mb-3' key={i}>
                                                //     <div><Shimmer height={64} className='rounded w-100' /></div>
                                                // </div>
                                                <div className='col-md-6 col-sm-12 mb-3' key={i}>
                                                <div className="accordion-item ">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${i}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                        <Shimmer height={20} width={350} className='rounded ' />
                                                        </button>
                                                    </h2>
                                                    <div id={i} className="accordion-collapse collapse">
                                                        <div className="accordion-body">
                                                            <p className='four_line mb-2'><Shimmer height={10} width={350} className='rounded ' /></p>
                                                            <p className='four_line mb-2'><Shimmer height={10} width={350} className='rounded ' /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }) :

                                            faqdata ? faqdata.map((r, i) => {
                                                return (
                                                    <>

                                                        <div className='col-md-6 col-sm-12 mb-3' key={i}>

                                                            <div className="accordion-item ">
                                                                <h2 className="accordion-header">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${r.id}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                                        {r.title}
                                                                    </button>
                                                                </h2>
                                                                <div id={r.id} className="accordion-collapse collapse">
                                                                    <div className="accordion-body">
                                                                        <p className='four_line'>{r.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                                )
                                            }) : ""
                                    }
                                </div>
                            </div> 

                        </div>
                    </div>


                    {/* 
<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">...</div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div> */}

                    {/* <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      4. Can we buy and store Digi Gold ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree">
                     2. How to convert Digi Gold to Jewellery ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree3" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree3">
                     5. Can we cancel the purchase at any time ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree3" className="accordion-collapse collapse show">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div> 

            <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree4" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree4">
                    3. How often does the live price change ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree4" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-6 mb-3'>
                 <div className="accordion-item">
                     <h2 className="accordion-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree6" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree6">
                      6. What is live gold price ?
                     </button>
                     </h2>
                     <div id="panelsStayOpen-collapseThree6" className="accordion-collapse collapse">
                     <div className="accordion-body">
                      <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                     </div>
                     </div>
                 </div>
            </div> */}
                </div>

            </div>
        </div>
    )
}
