
import { useFormik } from "formik"
import React, { useContext, useState } from "react"
import * as Yup from 'yup'
import { Resetpwd } from "./Resetpwd"
import { verify_pwd } from "../../api/Controllapi"
import { Logindetail } from "../../context/Logindetail"
import toast from "react-hot-toast"

export const Forgatpwd = () => {

    const [spinner ,setspinner] =useState(false)
    const {setemailname} = useContext(Logindetail)



    const [initialValues, setinitialValues] = useState({
        "email": ""
    })

    const validationSchema = Yup.object().shape({
        "email": Yup.string().email('Invalid Email')
            .required('Enter Your Email')
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/ , 'Invalid Email'
            )
            .test(
           'Invalid Email',
             (value)=>{
                return  value === value.toLowerCase()
             }
            ),
    })


    const Formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
            setemailname(value?.email)
            setspinner(true)
            await verify_pwd(value)
                .then((res) => {
                    setspinner(false)
                    toast.success(res?.message)
                    resetForm()                
                    document.getElementById('frgtpwd_closemodal')?.click()
                })

                .catch(err => {
                    toast.error(err?.response?.data?.message)
                    setspinner(false)

                 
                })

            }
            
          
    })

    const openlogin =()=>{
        document.getElementById('loginopen')?.click()
    }


return (
    <>
        <div className="modal fade" id="frgtpwd_modal"    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content px-4 py-2">
                    <div className="modal-header login-header forgotmodal text-center border-0" style={{ display: "block" }}>
                        <h3 className="modal-title" id="exampleModalLabel">Forgot Password</h3>
                        <p>Remember Your password ? <span onClick={openlogin}>Login here</span></p>
                        <button type="button" className="d-none" id="frgtpwd_closemodal"  data-bs-toggle="modal" data-bs-target="#resetmodal" ></button>
                        <button type="button" className="d-none" id="loginopen" data-bs-toggle="modal" data-bs-target="#loginmodalview"></button>
                    </div>
                    <div className="modal-body login-text pt-0">
                        <form onSubmit={Formik.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <label>Email</label>
                                    <input name="email" placeholder="Email" className="form-control border" {...Formik.getFieldProps("email")} />
                                    {
                                        Formik.touched.email || Formik.errors.email ? (<span className="text-danger">{Formik.errors.email}</span>) : ""
                                    }
                                </div>
                                <div className="col-lg-12 text-center my-3">
                                <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Verify'}</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

        <Resetpwd />

    </>
)
}
