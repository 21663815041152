import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import * as Yup from 'yup'
import { useFormik } from "formik";
import { Logindetail } from "../context/Logindetail.js";
import { bankdetail, baseurl, getBankDetail } from "../api/Pageapi.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Shimmer } from "react-shimmer";
import toast from "react-hot-toast";
import { accountdetails_fun, getprofile_fun, update_fun } from "../api/Dashboardapi.js";
import { X } from "react-bootstrap-icons";

const MyBankAccount = ({ settings }) => {
    const { setdetail_info } = useContext(Logindetail)
    const [spinner, setspinner] = useState(false)
    const [acc_list, setacc_list] = useState({})
    const [Isloading, setIsLoading] = useState(true)
    const [bankaccount, setbankaccount] = useState({
        "holder_name": '',
        "account_number": '',
        "confirm_account_number": '',
        "ifsc": '',
        "account_type": '',
        "bank_name": '',
        "branch": '',
        "gpay": '',
        "upi": '',
    })



    const updateschema = Yup.object().shape({
        "holder_name": Yup.string()
            .required('Enter Account Holder Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        "account_number": Yup.string()
            .required("Enter Account Number")
            .matches(/^[0-9]+$/, "Invalid  Account Number")
            .min(12, 'Account number minimum 12 digits'),


        "confirm_account_number": Yup.string()
            .required('Enter Confirm Account Number')
            .matches(/^[0-9]+$/, "Invalid  Account Number")
            .min(12, 'Account number minimum 12 digits')
            .oneOf([Yup.ref('account_number'), null], 'Account Number must be a Same'),

        "ifsc": Yup.string().required('Enter IFSC Code')
            .matches(/^[A-Z0-9]+$/, "Invalid IFSC Code")
            .min(11, ' Ifsc code Must be 11 digits'),


        // "account_type": Yup.string()
        // .required('Enter Account Type'),

        "bank_name": Yup.string().required('Enter Bank Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        "branch": Yup.string().required('Enter Branch Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        "gpay": Yup.string().required('Enter Gpay Number')
            .min(10, 'Gpay Number Minimum 10 digits')
            .max(10, 'Gpay Number Maximum 10 digits')
            .matches(/^[0-9]+$/, "Invalid  Account Number"),

        "upi": Yup.string()
            .required('Enter UPI ID')
            .matches(/^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/, 'Invalid UPI ID')

        // .matches(/^[a-zA-Z0-9 ]*$/, "Invalid Ifsc Code"),
    })


    const Formik = useFormik({
        initialValues: bankaccount,
        validationSchema: updateschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
            setspinner(true)
            update_fun(value)
                .then((res) => {
                    console.log(res)
                    document.getElementById('clode_accountmodal')?.click()
                    toast.success(res?.message)
                    getAccountDetail()
                    resetForm()
                    setspinner(false)
                })
                .catch((err) => {
                    console.log(err)
                    setspinner(false)
                })
        }
    });


    const getAccountDetail = async () => {
        getprofile_fun()
            .then((res) => {
                setacc_list(res?.data?.account_detail)
                localStorage.setItem("green_login", JSON.stringify(res?.data));
                setdetail_info(res?.data)
                setIsLoading(false);
                // setbankaccount({"holder_name" : res?.holder_name , "account_number" : res?.account_number , "confirm_account_number" : res?.account_number , "ifsc":res?.ifsc , "account_type" : res?.account_type})
                Formik.setFieldValue("holder_name", res?.data?.account_detail?.holder_name)
                Formik.setFieldValue("account_number", res?.data?.account_detail?.account_number)
                Formik.setFieldValue("confirm_account_number", res?.data?.account_detail?.account_number)
                Formik.setFieldValue("ifsc", res?.data?.account_detail?.ifsc)
                Formik.setFieldValue("account_type", res?.data?.account_detail?.account_type)
                Formik.setFieldValue("bank_name", res?.data?.account_detail?.bank_name)
                Formik.setFieldValue("branch", res?.data?.account_detail?.branch)
                Formik.setFieldValue("gpay", res?.data?.account_detail?.gpay)
                Formik.setFieldValue("upi", res?.data?.account_detail?.upi)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(true)
            })
    }

    useEffect(() => {
        getAccountDetail()
    }, [])



    return (
        <>
            <HeadingBanner title={"My Bank Accounts"} />
            <section>
                <div className="container">
                    <div className="mybankac-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="mybankac-content position-relative">
                                    <div className="mybank-head pb-3">
                                        <h4>My Bank Accounts</h4>
                                    </div>

                                    {
                                        Isloading ? (

                                            <>
                                        
                                                   <div className="tableload  tableload2 commision-load text-center position-absolute">
                                            <div class="spinner-border text-primary text-center" role="status"></div>
                                        </div>
                                            </>) :

                                            (
                                                <>
                                                    {

                                                        acc_list === null || acc_list?.account_number === null
                                                            ? (<div className="addbank-ac text-center border py-3">
                                                                <div className="bankimage my-3 ">
                                                                    <img src="\assets\iocns\bank.png" className="img-fluid" />
                                                                </div>
                                                                <div className="addbank-info ">
                                                                    <p>Add withdrawl account to get deposits of money at the time of selling</p>
                                                                </div>
                                                                <div className="addbank-btn text-white my-3">
                                                                    <button className="text-white" data-bs-toggle="modal" data-bs-target="#addbankmodal" data-bs-whatever="@getbootstrap" >Add Bank Account</button>
                                                                </div>
                                                            </div>) :

                                                            (<div className="aclist border px-3  pt-4">
                                                                <div className="row">
                                                                    <div className=" col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
                                                                        <div className="ackey">
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\Profile.png"  alt=""/></span> Account Holder Name</h2>
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\bank.png"  alt=""/></span>Bank Name</h2>
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\branchlocatin.png"  alt=""/></span>Branch Name</h2>
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\accountnumber.png"  alt=""/></span>Account Number</h2>
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\gpay.png"  alt=""/></span>Gpay Number</h2>
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\upi.png"  alt=""/></span>UPI ID</h2>
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\ifsc code.png"  alt=""/></span>IFSC Code</h2>
                                                                            <h2> <span className="px-2"> <img src="\assets\iocns\accounttype.png"  alt=""/></span>Account Type</h2>

                                                                        </div>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-lg-6 col-md-6 col-sm-6 col-6">
                                                                        <div className="acvalue">
                                                                            <h2>:<span className="mx-md-5 mx-2 ">{acc_list?.holder_name ? acc_list?.holder_name : '' }</span></h2>
                                                                            <h2>:<span className="mx-md-5 mx-2 ">{acc_list?.bank_name ? acc_list?.bank_name : ''}</span></h2>
                                                                            <h2>:<span className="mx-md-5 mx-2 "> {acc_list?.branch ? acc_list?.branch : ''}</span></h2>
                                                                            <h2>:<span className="mx-md-5 mx-2 ">{acc_list?.account_number ? acc_list?.account_number : ''}</span></h2>
                                                                            <h2>:<span className="mx-md-5 mx-2 ">{acc_list?.gpay ? acc_list?.gpay : ''}</span></h2>
                                                                            <h2>:<span className="mx-md-5 mx-2 ">{acc_list?.upi ? acc_list?.upi : ''}</span></h2>
                                                                            <h2>:<span className="mx-md-5 mx-2 ">{acc_list?.ifsc ? acc_list?.ifsc : ''}</span></h2>
                                                                            <h2>:<span className="mx-md-5 mx-2 ">{acc_list?.account_type ? acc_list?.account_type : ''}</span></h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button className="btn  text-white" data-bs-toggle="modal" data-bs-target="#addbankmodal" onClick={() => { getAccountDetail() }}>Edit</button>
                                                            </div>)
                                                    }
                                                </>)
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="modal fade Addbankacc-modal" id="addbankmodal" tabIndex={-1} aria-labelledby="addbankmodal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content py-3">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel"> {acc_list == null ? 'Create' : 'Edit'} Bank Accounts</h3>
                            <button className="d-none" id="clode_accountmodal" data-bs-toggle="modal" data-bs-target="#addbankmodal"></button>
                        </div>
                        <div className="modal-body bankdetail-input">
                            <form onSubmit={Formik.handleSubmit}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-12   form-group login-text">
                                            <label className="col-form-label">Account Holder Name</label>
                                            <input type="text" className="form-control" id="recipient-name" placeholder="Account Holder Name" {...Formik.getFieldProps("holder_name")} />
                                            {
                                                Formik.touched.holder_name && Formik.errors.holder_name ? (<p className="text-danger m-0">{Formik.errors.holder_name}</p>) : null
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6 form-group login-text">
                                            <label className="col-form-label">Account Number</label>
                                            <input type="text" className="form-control" id="recipient-name2" placeholder="Account Number"    {...Formik.getFieldProps("account_number")} />
                                            {
                                                Formik.touched.account_number && Formik.errors.account_number ? (<p className="text-danger m-0">{Formik.errors.account_number}</p>) : null
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                                            <label className="col-form-label">Confirm Account Number</label>
                                            <input type="text" className="form-control" id="recipient-name1" placeholder="Confirm Account Number"  {...Formik.getFieldProps("confirm_account_number")} />
                                            {
                                                Formik.touched.confirm_account_number && Formik.errors.confirm_account_number ? (<p className="text-danger m-0">{Formik.errors.confirm_account_number}</p>) : null
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                                            <label className="col-form-label">Bank Name</label>
                                            <input type="text" className="form-control" id="recipient-name3" placeholder="Bank Name"  {...Formik.getFieldProps("bank_name")} />
                                            {
                                                Formik.touched.bank_name && Formik.errors.bank_name ? (<p className="text-danger m-0">{Formik.errors.bank_name}</p>) : null
                                            }
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                                            <label className="col-form-label">Branch Name</label>
                                            <input type="text" className="form-control" id="recipient-name3" placeholder="Branch Name"  {...Formik.getFieldProps("branch")} />
                                            {
                                                Formik.touched.branch && Formik.errors.branch ? (<p className="text-danger m-0">{Formik.errors.branch}</p>) : null
                                            }
                                        </div>


                                        <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                                            <label className="col-form-label">IFSC Code</label>
                                            <input type="text" className="form-control" id="recipient-name3" placeholder="IFSC Code"  {...Formik.getFieldProps("ifsc")} />
                                            {
                                                Formik.touched.ifsc && Formik.errors.ifsc ? (<p className="text-danger m-0">{Formik.errors.ifsc}</p>) : null
                                            }
                                        </div>


                                        <div className="col-md-6 col-sm-6 col-6  form-group login-text" >
                                            <label className="col-form-label">Account Type</label>
                                            <select className="form-select" aria-label="addbankname" {...Formik.getFieldProps("account_type")}>
                                                <option value="" selected disabled>Select</option>
                                                <option value="Savings">Savings</option>
                                                <option value="Current">Current</option>
                                            </select>
                                            {
                                                Formik.touched.account_type && Formik.errors.account_type ? (<p className="text-danger m-0">{Formik.errors.account_type}</p>) : null
                                            }
                                        </div>
                                        <div className="border  my-3 p-0"></div>

                                        <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                                            <label className="col-form-label">Gpay Number</label>
                                            <input type="text" className="form-control" id="recipient-name3" placeholder="Gpay Number"  {...Formik.getFieldProps("gpay")} />
                                            {
                                                Formik.touched.gpay && Formik.errors.gpay ? (<p className="text-danger m-0">{Formik.errors.gpay}</p>) : null
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6  form-group login-text">
                                            <label className="col-form-label">UPI ID</label>
                                            <input type="text" className="form-control" id="recipient-name3" placeholder="UPI Number"  {...Formik.getFieldProps("upi")} />
                                            {
                                                Formik.touched.upi && Formik.errors.upi ? (<p className="text-danger m-0">{Formik.errors.upi}</p>) : null
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="addbank-submit py-3 mx-3">
                                    <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Sumbit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default MyBankAccount;