import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Approutes from './routing/Approutes.jsx';
import './Style/style.css'
import "swiper/css";
import "swiper/css/free-mode";

import "swiper/css/pagination";
import "swiper/css/grid";
import { Logindetail } from './context/Logindetail.js';
import { Footerpages } from './context/Footerpages.js'
import "aos/dist/aos.css";
import AOS from 'aos'
import toast, { Toaster } from 'react-hot-toast';
import { SchemeDetails } from './context/SchemeDetails.js';
import "react-datepicker/dist/react-datepicker.css";



function App() {

  const [detail_info, setdetail_info] = useState(JSON.parse(localStorage.getItem('green_login')));
  const [token, settoken] = useState(localStorage.getItem('green_token'))
  // const [settings, setsettings] = useState()
  const [footerpage, setfooterpage] = useState()
  const [emailname, setemailname] = useState()
  const [schemelist, setschemelist] = useState([])

  useEffect(() => {

    setdetail_info(JSON.parse(localStorage.getItem('green_login')));
    settoken(localStorage.getItem('green_token'));
  }, [localStorage.getItem('green_login'),  localStorage.getItem('green_token')])


  return (
    <div className="">
      <Logindetail.Provider value={{ detail_info, setdetail_info, settoken, token,  emailname, setemailname }}>
        <Footerpages.Provider value={{ footerpage, setfooterpage }}>
          <SchemeDetails.Provider value={{schemelist , setschemelist}}>
            <Approutes />
          </SchemeDetails.Provider>
        </Footerpages.Provider>
      </Logindetail.Provider>
      <Toaster />
    </div>
  );
}

export default App;
